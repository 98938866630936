import * as React from 'react';
import {
  Container,
  Button,
  Spinner
} from 'react-bootstrap';
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import qs from 'query-string';

import ReservationInfo from '../../molecules/ReservationInfo/ReservationInfo'

import * as styles from './ConfirmChangePurchaseRequestPage.scss';
import { PurchaseRequestDetail } from '../../../types/purchaseRequestDetail';
import { SetReservationDataContext } from '../../../providers/ReservationContextProvider';

type Props = RouteComponentProps<{
  purchaseRequestId: string
}>

const Component: React.FC<Props> = ({ match }: Props) => {
  const history = useHistory()
  const [targetPurchaseRequest, setTargetPurchaseRequest] = React.useState<PurchaseRequestDetail>()
  const [invokingApi, setInvokingApi] = React.useState(false)
  const setReservationData = React.useContext(SetReservationDataContext)

  const storePrevDataAndMoveSchedulePage = async () => {
    if (!targetPurchaseRequest) {
      return;
    }

    let token = ''
    try {
      setInvokingApi(true)
      const { data } = await axios.post<{token: string}>(
        `${process.env.API_BASE_URL}/api/web-app/phone-tokens`,
        {
          hashedScheduleId: qs.parse(location.search).scheduleId,
          purchaseRequestId: targetPurchaseRequest.purchaseRequestId,
        }
      )

      setInvokingApi(false)
      token = data.token
    } catch (err) {
      // @ts-ignore
      const errorTxt = err?.response?.data?.error || 'エラーが発生しました。\n既にキャンセル/日時変更済み、もしくはURLが間違っている可能性があります。'
      setInvokingApi(false)
      return alert(errorTxt)
    }

    // Contextの形式に合わせるために電話番号をハイフンつなぎに変換
    const mobilePhoneWithoutHyphen = targetPurchaseRequest.mobilePhone
    const splitMobilePhone = ('0' + mobilePhoneWithoutHyphen.replace(/\+81/, '')).replace(/\D/g, '').match(/(\d{0,3})(\d{0,4})(\d{0,4})/) as RegExpMatchArray
    const mobilePhoneWithHyphen = splitMobilePhone[1] + (splitMobilePhone[2] ? '-' + splitMobilePhone[2] : '') + (splitMobilePhone[3] ? '-' + splitMobilePhone[3] : '');
    const beforeAssessmentKpiId = qs.parse(location.search).assessmentKpiId as string | null

    setReservationData((prev) => ({
      ...prev,
      remarks: targetPurchaseRequest.remarks,
      selectedQty: targetPurchaseRequest.selectedQty,
      mobilePhone: mobilePhoneWithHyphen,
      beforeRequestId: Number(match.params.purchaseRequestId),
      beforeAssessmentKpiId: Number(beforeAssessmentKpiId),
      token,
      isChangeReservation: true
    }))

    return history.push('/purchase-request/schedules')
  }

  // クエリパラメータを元に該当の予約を特定
  React.useEffect(() => {
    ; (async () => {
      setInvokingApi(true)
      const purchaseRequestId = match.params.purchaseRequestId
      const hashedScheduleId = qs.parse(location.search).scheduleId as string | null

      if (!purchaseRequestId || !hashedScheduleId) {
        return alert('エラーが発生しました。URLが正しいかご確認ください。')
      }

      try {
        const { data } = await axios.get<PurchaseRequestDetail>(
          `${process.env.API_BASE_URL}/api/web-app/purchase-requests/${purchaseRequestId}`,
          {
            params: {
              hashedScheduleId
            }
          }
        )
        setTargetPurchaseRequest(data)
        setInvokingApi(false)
      } catch (err) {
        // @ts-ignore
        const errorTxt = err?.response?.data?.error || 'エラーが発生しました。\n既にキャンセル/日時変更済み、もしくはURLが間違っている可能性があります。'
        alert(errorTxt);
      }
    })()
  }, [])

  return (
    <Container className={styles.webappContainer}>
      <Helmet>
        <title>日時変更確認 | ブランディアBell</title>
      </Helmet>

      {invokingApi && (
        <div className={styles.screenOverlay}>
          <Spinner animation="border" variant="info" />
        </div>
      )}

      {targetPurchaseRequest && (
        <div>
          <h4 className={`mt-5 mb-4 text-left ${styles.boldText}`}>
            下記の予約日時を変更します。よろしいですか？
          </h4>

          <ul>
            <li>※変更完了で変更前の日時は自動的にキャンセルされます</li>
            <li>※お品物数は当日接客員にご相談ください。</li>
          </ul>

          <ReservationInfo
            startAt={new Date(targetPurchaseRequest.startAt)}
            selectedQty={targetPurchaseRequest.selectedQty}
            remarks={targetPurchaseRequest.remarks}
          />

          <div className="text-center my-4">
            <Button
              className={`mt-5 mb-2 pb-3 pt-3 btn-block ${styles.button}`}
              type="button"
              variant="light"
              disabled={invokingApi}
              onClick={() => storePrevDataAndMoveSchedulePage()}
            >
              変更手続きへ進む
            </Button>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Component;
