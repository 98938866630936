import * as React from 'react';
import {
  Container,
  Button,
  Spinner
} from 'react-bootstrap';
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import qs from 'query-string';

import ReservationInfo from '../../molecules/ReservationInfo/ReservationInfo'

import * as styles from './ConfirmCancelPurchaseRequestPage.scss';
import { PurchaseRequestDetail } from '../../../types/purchaseRequestDetail';

type Props = RouteComponentProps<{
  purchaseRequestId: string
}>

const Component: React.FC<Props> = ({ match }: Props) => {
  const history = useHistory();
  const [targetPurchaseRequest, setTargetPurchaseRequest] = React.useState<PurchaseRequestDetail>()
  const [invokingCancelApi, setInvokingCancelApi] = React.useState(false)

  const cancelReservation = async () => {
    if (!targetPurchaseRequest) {
      return;
    }

    setInvokingCancelApi(true)

    try {
      await axios.delete(
        `${process.env.API_BASE_URL}/api/web-app/purchase-requests/cancel`,
        {
          data: {
            purchaseRequestId: match.params.purchaseRequestId,
            hashedScheduleId: qs.parse(location.search).scheduleId,
          }
        }
      )

      history.push('/purchase-request/canceled', {
        startAt: targetPurchaseRequest.startAt,
        selectedQty: targetPurchaseRequest.selectedQty,
        remarks: targetPurchaseRequest.remarks,
      })
    } catch (e) {
      alert('エラーが発生しました。\n既にキャンセル、日時変更済みの可能性があります。')
      setInvokingCancelApi(false)
    }
  }

  // クエリパラメータを元に該当の予約を特定
  React.useEffect(() => {
    ; (async () => {
      setInvokingCancelApi(true)
      const purchaseRequestId = match.params.purchaseRequestId
      const hashedScheduleId = qs.parse(location.search).scheduleId as string | null

      if (!purchaseRequestId || !hashedScheduleId) {
        return alert('エラーが発生しました。URLが正しいかご確認ください。')
      }

      try {
        const { data } = await axios.get<PurchaseRequestDetail>(
          `${process.env.API_BASE_URL}/api/web-app/purchase-requests/${purchaseRequestId}`,
          {
            params: {
              hashedScheduleId
            }
          }
        )
        setTargetPurchaseRequest(data)
        setInvokingCancelApi(false)
      } catch (err) {
        // @ts-ignore
        const errorTxt = err?.response?.data?.error || 'エラーが発生しました。\n既にキャンセル済み、もしくはURLが間違っている可能性があります。'
        alert(errorTxt);
      }
    })()
  }, [])

  return (
    <Container className={styles.webappContainer}>
      <Helmet>
        <title>キャンセル確認 | ブランディアBell</title>
      </Helmet>

      {invokingCancelApi && (
        <div className={styles.screenOverlay}>
          <Spinner animation="border" variant="info" />
        </div>
      )}

      {targetPurchaseRequest && (
        <div>
          <h4 className={`mt-5 mb-4 text-left ${styles.boldText}`}>
            下記の予約をキャンセルします。よろしいですか？
          </h4>

          <ReservationInfo
            startAt={new Date(targetPurchaseRequest.startAt)}
            selectedQty={targetPurchaseRequest.selectedQty}
            remarks={targetPurchaseRequest.remarks}
          />

          <div className="text-center my-4">
            <Button
              className={`mt-5 mb-2 pb-3 pt-3 btn-block ${styles.button}`}
              type="button"
              variant="light"
              disabled={invokingCancelApi}
              onClick={() => cancelReservation()}
            >
              キャンセルする
            </Button>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Component;
