import React from 'react';
import qs from 'query-string';
import axios from 'axios';
import { Spinner, Container } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import PurchaseDetailForm from 'line-app/src/components/molecules/PurchaseDetailForm/PurchaseDetailForm';
import PickupDetailForm from 'line-app/src/components/molecules/PickupDetailForm/PickupDetailForm';
import ConfirmPurchaseDetail from 'line-app/src/components/molecules/ConfirmPurchaseDetail/ConfirmPurchaseDetail';

import * as styles from './PurchaseDetailPage.scss';

import {
  AccountInfo,
  JobEnum,
  PickupDateTime,
  PickupTimeFrameEnum,
  ReferralFriend,
  UserInfo,
  BusinessInvoice,
  DataBank,
  BranchBank,
  SelectedDataBank,
  SelectedBranchBank,
  SelectedCodeBank,
  PickupAddress,
  AddressWithPostcode
} from 'line-app/src/types/types'
import { MultiValue, SingleValue } from 'react-select';
import { LimitAPIBankCore } from '../../../constants';
import {PurchaseDetailNameSpace} from "line-app/src/types/enums";

const Component: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<{isConsentChecked?: boolean}>();

  // 制御用用State
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [submitDisabled, setSubmitDisabled] = React.useState<boolean>(false);

  const [dataBank, setDataBank] = React.useState<DataBank[]>([]);
  const [branchBank, setBranchBank] = React.useState<BranchBank[]>([]);
  const [codeBank, setCodeBank] = React.useState<BranchBank[]>([]);
  const [selectedOptionBankName, setSelectedOptionBankName] =  React.useState<SelectedDataBank | null>(null);
  const [selectedOptionBankBranch, setSelectedOptionBankBranch] = React.useState<SelectedBranchBank | null>(null);
  const [selectedOptionBankCode, setSelectedOptionBankCode] = React.useState<SelectedCodeBank | null>(null);
  // form送信用State
  //// 本人情報（必須）
  const [userInfo, setUserInfo] = React.useState<UserInfo>({
    userName: '',
    userSurname: '',
    userNameKana: '',
    userSurnameKana: '',
    postcode: '',
    address: '',
    address1: '',
    address2: '',
    birthday: '',
    email: '',
    job: '',
    detailJobOther: ''
  });

  //// 口座情報
  const [accountInfo, setAccountInfo] = React.useState<AccountInfo>({
    bankName: '',
    branchNumber: '',
    branchName: '',
    accountType: '',
    accountNumber: '',
    accountName: '',
  });

  //// 集荷希望日時
  const [pickupDateTime, setPickupDateTime] = React.useState<PickupDateTime>({
    pickupDate: '',
    pickupTimeFrame: '',
  });

  // 集荷先住所
  const [pickupAddress, setPickupAddress] = React.useState<string>('');

  //// お友達情報（必須）
  const [referralFriend, setReferralFriend] = React.useState<ReferralFriend>({
    friendName: '',
    usedDate: '',
  });

  // 集荷先住所関連
  const [pickupAddressChecked, setPickupAddressChecked] = React.useState<boolean>(false)
  const [pickupAddressInfo, setPickupAddressInfo] = React.useState<PickupAddress>({
    pickupPostcode: '',
    pickupAddress: '',
    pickupAddress1: '',
    pickupAddress2: '',
    pickupPref: '',
    pickupTown: '',
    pickupCity: '',
  })


  // 適格請求書発行事業者ですか（個人でご利用のお客様は「いいえ」をお選びください
  const [businessInvoice, setBusinessInvoice] = React.useState<BusinessInvoice>({
    isBusinessInvoice: false,
    businessInvoiceRegistrationNumber: ''
  });

  // メルマガ許諾
  const [isPermittedMailMagazine, setIsPermittedMailMagazine] = React.useState<boolean>(false)

  // Enum State
  const [jobEnum, setJobEnum] = React.useState<JobEnum | any>({})
  const [pickupTimeFrameEnum, setPickupTimeFrameEnum] = React.useState<PickupTimeFrameEnum | any>({})

  // Other state
  const [purchaseFormId, setPurchaseFormId] = React.useState<string>('')
  const [formTypeId, setFormTypeId] = React.useState<string>('')
  const [hashedPurchaseRequestId, setHashedPurchaseRequestId] = React.useState<string>('')

  // FormTypeIdの判別: boolean
  const isPurchaseForm_2: boolean = formTypeId === '2';
  const isPurchaseForm_3: boolean = formTypeId === '3';
  // const isPurchaseForm_4: boolean = formTypeId === '4';
  const isPurchaseForm_5: boolean = formTypeId === '5';
  const isPurchaseForm_6: boolean = formTypeId === '6';
  const isPurchaseForm_7: boolean = formTypeId === '7';
  const pickupDateInterval =  (formTypeId === '4' || formTypeId === '5' ? 3 : 0)

  //is submitted button
  const [showConfirmation, setShowConfirmation] = React.useState<boolean>(false);

  const [loadingPartialAddressFromPostcodeAPI, setLoadingPartialAddressFromPostcodeAPI] = React.useState<boolean>(false)

  const [inputBankName, setInputBankName] = React.useState<string>('')
  const [inputBranchNumber, setInputBranchNumber] = React.useState<string>('')
  const [inputBranchName, setInputBranchName] = React.useState<string>('')

  // APIリクエスト時のエラーハンドリング
  const handleApiError = (): void => {
    alert('エラーが発生しました。再度ページをお開きいただくか、LINE上でお問い合わせください。')
    history.goBack()
  }

  /**
   * 買取"情報"フォームの場合は同意項目を介しているかチェック
   */
  const isAlreadyConsentChecked = (purchaseFormIdParam: string): boolean => {
    // 集荷フォームは検証不要
    return !!(purchaseFormIdParam === '2' || state?.isConsentChecked);
  }

  // 入力可能なフォームか確認
  const verifyValidForm = async (
    purchaseFormId: string,
    formTypeId: string,
    hashedPurchaseRequestId: string
  ) => {
    try {
      const { data } = await axios.get<{purchaseFormId: number}>(
        `${process.env.API_BASE_URL}/api/web-app/purchase-forms/${purchaseFormId}?formTypeId=${formTypeId}&hashedRequestId=${hashedPurchaseRequestId}`,
      )

      // 該当のpurchaseFormが存在しない
      if (Object.keys(data).length === 0) {
        alert("エラーが発生しました。\nURLが正しいかご確認ください。");
      }
    } catch (e) {
      handleApiError()
    }
  }

  // 既に入力済みのデータがないか判定
  const verifyInputtedDataNotExists = async (purchaseFormId: string) => {
    try {
      const { data } = await axios.get(
        `${process.env.API_BASE_URL}/api/web-app/purchase-forms/${purchaseFormId}/purchase-details`,
      )
      // purchase_form_id に紐づくpurchase_detailsが見つかる => 入力済みのフォームと判断
      if (Object.keys(data).length > 0) {
        alert("すでに入力し、送信済みです。");
        return history.goBack()
      }
    } catch (e) {
      handleApiError()
    }
  }

  // job(職業)のEnum一覧取得
  const getJobEnum = async () => {
    try {
      const { data } = await axios.get<JobEnum>(
        `${process.env.API_BASE_URL}/api/enum/jobs`
      )
      setJobEnum(data)
    } catch (e) {
      handleApiError()
    }
  }
  const getDataFromBankCoreAPI = () => {
    try {
      axios.get(
          `${process.env.API_URL_BANKCORE}`,
          {
            params: {
              limit: LimitAPIBankCore,
              apikey: process.env.API_KEY_BANKCORE,
            },
          }
      ).then((data) => {
        let res = (data?.data?.data)
        setDataBank(res)
          }
      );
    } catch (error) {
      handleApiError()
    }
  };

  // pickupTimeFrame(希望集荷時間枠)のEnum一覧取得
  const getPickupTimeFrameEnum = async () => {
    try {
      const { data } = await axios.get<PickupTimeFrameEnum>(
        `${process.env.API_BASE_URL}/api/enum/pick-up-time-frames`
      )
      setPickupTimeFrameEnum(data)
    } catch (e) {
      handleApiError()
    }
  }

  // 実行環境に左右されず常にJSTを取得
  const jstNow = (): Date => {
    const currentDate = new Date(Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000));
    currentDate.setDate(currentDate.getDate() + pickupDateInterval);
    return currentDate;
  }

  // 今日を含めてmaxDays日先のDateオブジェクトの配列を取得
  const getDatesFromTomorrowToNthDays = (maxDays: number): Date[] => {
    // 年末年始の営業時間を考慮して、12/28~1/4分は非表示にしてその分先にずらすため
    const disableDates = ['12/28', '12/29', '12/30', '12/31', '1/1', '1/2', '1/3', '1/4']

    const dates: Date[] = [];
    let isDisabledDatesIncluded = false // disabledDatesに含まれない日もずらす必要があるため

    // 今日がスキップ対象日に含まれるかでずらす日数を分ける
    const todayStr = `${jstNow().getMonth() + 1}/${jstNow().getDate()}`
    const skipLength = disableDates.includes(todayStr)
      ? disableDates.length - (disableDates.findIndex((disableDateStr) => disableDateStr === todayStr) + 1)
      : disableDates.length
    for (var i = 1; i <= maxDays; i++) {
      const date: Date = jstNow()
      date.setDate(jstNow().getDate() + i)
      if (isDisabledDatesIncluded || disableDates.includes(`${date.getMonth() + 1}/${date.getDate()}`)) {
        date.setDate(date.getDate() + skipLength)
        isDisabledDatesIncluded = true
      }
      dates.push(date)
    }

    return dates
  }

  // 選択不可のOptionか判定
  // 午後20時以降は翌日の「①8時～13時」は選択不可
  const disabledOption = (value: string): boolean => {
    const now = jstNow()
    // pickupDate(集荷希望日)が翌日日以降であればfalse
    if (
      new Date(pickupDateTime.pickupDate) > new Date(jstNow().setDate(now.getDate() + 1))
    ) {
      return false
    }

    if (
      value == pickupTimeFrameEnum.FROM_8_TO_13
      && now.getHours() > 20
    ) {
      return true
    }

    return false
  }

  const handlePurchaseDetailForm = async () => {
      setSubmitDisabled(false);
      setShowConfirmation(true);
  }

  const handleSubmit = async () => {
    try {
      setSubmitDisabled(true);
      const updatedUserInfo = {
        ...userInfo,
        detailJobOther: userInfo.job !== PurchaseDetailNameSpace.JobEnum.OTHER ? null : userInfo.detailJobOther,
      };

      await axios
        .post(
          `${process.env.API_BASE_URL}/api/web-app/purchase-forms/${purchaseFormId}/purchase-details`,
          {
            ...updatedUserInfo,
            ...accountInfo,
            ...pickupDateTime,
            ...referralFriend,
            ...businessInvoice,
            pickupAddressChecked,
            ...pickupAddressInfo,
            isPermittedMailMagazine,
            formTypeId,
            hashedRequestId: hashedPurchaseRequestId,
          },
        )

      alert('送信に成功しました。ご入力ありがとうございました。')
      location.href = 'https://brandear.jp/bell'
    } catch (err: any) {
      setSubmitDisabled(false);
      setShowConfirmation(false)
      const res = err.response

      // validationに引っ掛かった時
      if (
        res.status == 400
        && res.data.length > 0
      ) {
        alert(
          "エラーが発生しました。\n次の入力項目に誤りがあります: " + res.data.join(', ')
        )
        return;
      }

      // その他
      alert(
        'エラーが発生しました。入力項目,URLに誤りがないか再度ご確認ください。'
      )
    }
  }

  React.useEffect(() => {
    ;(async () => {
      const purchaseFormIdParam = qs.parse(location.search).formId as string | null;
      const purchaseFormTypeIdParam = qs.parse(location.search).formTypeId as string | null;
      const hashedPurchaseRequestIdParam = qs.parse(location.search).requestId as string | null;
      const isIssueUrlParam = qs.parse(location.search).isIssueUrl as string | null;
      getDataFromBankCoreAPI()
      if (!purchaseFormIdParam || !purchaseFormTypeIdParam || !hashedPurchaseRequestIdParam) {
        return handleApiError()
      }

      // 買取"情報"フォームの場合は同意項目を介しているかチェック
      if (!isIssueUrlParam && !isAlreadyConsentChecked(purchaseFormTypeIdParam)) {
        return handleApiError()
      }

      // 有効なフォームか検証
      const verifyValidFormReq = verifyValidForm(purchaseFormIdParam, purchaseFormTypeIdParam, hashedPurchaseRequestIdParam)
      const verifyInputtedDataNotExistsReq = verifyInputtedDataNotExists(purchaseFormIdParam)

      // Enum値取得
      const getPickupTimeFrameEnumReq = getPickupTimeFrameEnum()
      const getJobEnumReq = getJobEnum()

      // APIリクエストを並列処理
      await verifyValidFormReq
      await verifyInputtedDataNotExistsReq
      await getPickupTimeFrameEnumReq
      await getJobEnumReq

      // メアド入力項目を含む買取"情報"フォームはチェック済み、含まない"集荷"フォームは未選択状態にする
      setIsPermittedMailMagazine(purchaseFormTypeIdParam !== '2')

      setPurchaseFormId(purchaseFormIdParam)
      setFormTypeId(purchaseFormTypeIdParam)
      setHashedPurchaseRequestId(hashedPurchaseRequestIdParam)
      setIsLoading(false)
    })()
  }, []);

  // 郵便番号から住所を補完する（7文字のみ）
  const getPartialAddressFromPostcodeAPI = async (postcode: string):Promise<AddressWithPostcode|null> => {
    setLoadingPartialAddressFromPostcodeAPI(true)
    // 郵便番号-->住所してくれるAPIを無駄に呼ばないためにformat check
    const regex = new RegExp(/^[0-9]{7}$/g);
    if (!regex.test(postcode)) {
      return null
    }

    try {
      const res = await axios.get(
        `https://apis.postcode-jp.com/api/v4/postcodes/${postcode}`,
        {
          headers: {
            apikey: process.env.POSTCODE_API_KEY
          },
        }
      )
      setLoadingPartialAddressFromPostcodeAPI(false)
      return res.data[0] as AddressWithPostcode
    } catch {
      setLoadingPartialAddressFromPostcodeAPI(false)
      return null
    }
  }
  const handleChangeBank = async (value: MultiValue<SelectedDataBank> | SingleValue<SelectedDataBank>) => {
    const selectDataBank = value as SelectedDataBank;
    setSelectedOptionBankName(selectDataBank);
    setAccountInfo({ ...accountInfo, bankName: selectDataBank.value })
    const resBranchBank = await getBranchBank(selectDataBank.data)
    setBranchBank(resBranchBank)
    setCodeBank(resBranchBank)
    setInputBranchNumber('')
    setInputBranchName('')
  }

  const handleChangeBankBranch = (value: MultiValue<SelectedBranchBank> | SingleValue<SelectedBranchBank>) => {
    const selectBranchBank = value as SelectedBranchBank;
    setSelectedOptionBankBranch(selectBranchBank);
    setAccountInfo({ ...accountInfo, branchName: selectBranchBank.value, branchNumber: selectBranchBank.data })
    setSelectedOptionBankCode({ ...setSelectedOptionBankCode, label: selectBranchBank.data, value:selectBranchBank.data, name: selectBranchBank.value });
  }
  const handleChangeBankCode = (value: MultiValue<SelectedCodeBank> | SingleValue<SelectedCodeBank>) => {
    const selectCodeBank = value as SelectedCodeBank;
    setSelectedOptionBankCode(selectCodeBank);
    setAccountInfo({ ...accountInfo, branchNumber: selectCodeBank.value, branchName: selectCodeBank.name })
    setSelectedOptionBankBranch({ ...setSelectedOptionBankBranch, label: selectCodeBank.name, value:selectCodeBank.name, data: selectCodeBank.value });
  }

  const handleInputBankName = (value: string) => {
    setInputBankName(value);
    setAccountInfo({ ...accountInfo, bankName: value, branchName: null, branchNumber: null })
    setSelectedOptionBankBranch(null)
    setSelectedOptionBankCode(null)
    setBranchBank([])
    setCodeBank([])
    if(value === '') {
      setInputBranchNumber('')
      setInputBranchName('')
      setBranchBank([])
      setCodeBank([])
    }
  }

  const handleInputBranchNumber = (value:string) => {
    setInputBranchNumber(value)
    setAccountInfo({ ...accountInfo, branchNumber: value })
  }

  const handleInputBranchName = (value:string) => {
    setInputBranchName(value)
    setAccountInfo({ ...accountInfo, branchName: value })
  }

  const getBranchBank = async (codeBank?: string): Promise<BranchBank[]> => {
    try {
      if (codeBank) {
        const res = await axios.get<BranchBank>(
            `${process.env.API_URL_BANKCORE}/${codeBank}/branches`,
            {
              params: {
                apikey: process.env.API_KEY_BANKCORE,
              },
            }
        );
        return res?.data?.data;
      }
      return [];
    } catch (error) {
      return [];
    }
  };
  return (
      <div>
      {!showConfirmation ? (
      <Container className={styles.webappContainer}>
      {
        isLoading && (
          <div className={styles.screenOverlay}>
            <Spinner animation="border" variant="primary" />
          </div>
        )
      }
      {
        isPurchaseForm_2 || isPurchaseForm_5 || isPurchaseForm_7
          ? <PickupDetailForm
              accountInfo={accountInfo}
              pickupDateTime={pickupDateTime}
              pickupTimeFrameEnum={pickupTimeFrameEnum}
              userInfo={userInfo}
              submitDisabled={submitDisabled}
              setAccountInfo={setAccountInfo}
              setPickupDateTime={setPickupDateTime}
              setPickupAddress={setPickupAddress}
              setUserInfo={setUserInfo}
              getDatesFromTomorrowToNthDays={getDatesFromTomorrowToNthDays}
              getPartialAddressFromPostcodeAPI={getPartialAddressFromPostcodeAPI}
              disabledOption={disabledOption}
              handlePurchaseDetailForm={handlePurchaseDetailForm}
              pickupAddressChecked={pickupAddressChecked}
              setPickupAddressChecked={setPickupAddressChecked}
              pickupAddressInfo={pickupAddressInfo}
              setPickupAddressInfo={setPickupAddressInfo}
              businessInvoice={businessInvoice}
              setBusinessInvoice={setBusinessInvoice}
              loadingPartialAddressFromPostcodeAPI={loadingPartialAddressFromPostcodeAPI}
              setLoadingPartialAddressFromPostcodeAPI={setLoadingPartialAddressFromPostcodeAPI}
              isPurchaseForm7={isPurchaseForm_7}
          />
          : <PurchaseDetailForm
              accountInfo={accountInfo}
              jobEnum={jobEnum}
              pickupDateTime={pickupDateTime}
              pickupTimeFrameEnum={pickupTimeFrameEnum}
              referralFriend={referralFriend}
              userInfo={userInfo}
              isPermittedMailMagazine={isPermittedMailMagazine}
              businessInvoice={businessInvoice}
              submitDisabled={submitDisabled}
              isPurchaseForm3={isPurchaseForm_3}
              isPurchaseForm6={isPurchaseForm_6}
              setAccountInfo={setAccountInfo}
              setPickupDateTime={setPickupDateTime}
              setReferralFriend={setReferralFriend}
              setUserInfo={setUserInfo}
              setIsPermittedMailMagazine={setIsPermittedMailMagazine}
              setBusinessInvoice={setBusinessInvoice}
              getDatesFromTomorrowToNthDays={getDatesFromTomorrowToNthDays}
              getPartialAddressFromPostcodeAPI={getPartialAddressFromPostcodeAPI}
              dataBank={dataBank}
              branchBank={branchBank}
              codeBank={codeBank}
              handleChangeBank={handleChangeBank}
              selectedOptionBankBranch={selectedOptionBankBranch}
              handleChangeBankBranch={handleChangeBankBranch}
              handleChangeBankCode={handleChangeBankCode}
              selectedOptionBankName={selectedOptionBankName}
              selectedOptionBankCode={selectedOptionBankCode}
              disabledOption={disabledOption}
              handlePurchaseDetailForm={handlePurchaseDetailForm}
              loadingPartialAddressFromPostcodeAPI={loadingPartialAddressFromPostcodeAPI}
              setLoadingPartialAddressFromPostcodeAPI={setLoadingPartialAddressFromPostcodeAPI}
              inputBankName={inputBankName}
              setInputBankName={setInputBankName}
              handleInputBankName={handleInputBankName}
              inputBranchNumber={inputBranchNumber}
              handleInputBranchNumber={handleInputBranchNumber}
              inputBranchName={inputBranchName}
              handleInputBranchName={handleInputBranchName}
          />
      }
    </Container>
      ) : (

          <ConfirmPurchaseDetail
              userInfo={userInfo}
              accountInfo={accountInfo}
              pickupDateTime={pickupDateTime}
              referralFriend={referralFriend}
              pickupAddress={pickupAddress}
              isPermittedMailMagazine={isPermittedMailMagazine}
              purchaseFormId={purchaseFormId}
              setShowConfirmation={setShowConfirmation}
              setSubmitDisabled={setSubmitDisabled}
              handleSubmit={handleSubmit}
              formTypeId={formTypeId}
              jobEnum={jobEnum}
              pickupTimeFrameEnum={pickupTimeFrameEnum}
              pickupAddressChecked={pickupAddressChecked}
              pickupAddressInfo={pickupAddressInfo}
              businessInvoice={businessInvoice}
          />
      )}
      </div>
  )
};

export default Component;
