import React, { Dispatch, SetStateAction, createContext, useState, ReactNode } from 'react'
import { ReservationData } from '../types/reservation'
import { MAX_QTY_FOR_60_MINUTES_FRAME } from 'line-app/src/enums/SelectablePurchaseItemsQty'

const defaultValue = {
  remarks: '',
  selectedQty: MAX_QTY_FOR_60_MINUTES_FRAME,
  scheduleId: 0,
  mobilePhone: '',
  isRealtime: false,
  beforeRequestId: null,
  beforeAssessmentKpiId: null,
  token: '',
  isChangeReservation: false,
}

export const ReservationDataContext = createContext<ReservationData>(defaultValue)

export const SetReservationDataContext = createContext<Dispatch<SetStateAction<ReservationData>>>(() => {})

/**
 * 予約に必要な情報を管理するProvider(パフォーマンスを考慮してgetter,setterを分けている)
 */
export default function ReservationContextProvider({
  children,
}: {
  children: ReactNode
}) {
  const [reservationData, setReservationData] = useState<ReservationData>(defaultValue)

  return (
    <ReservationDataContext.Provider value={reservationData} >
      <SetReservationDataContext.Provider value={setReservationData} >
        {children}
      </SetReservationDataContext.Provider>
    </ReservationDataContext.Provider>
  )
}
