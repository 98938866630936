import * as React from 'react';
import {
  Container,
  Button,
} from 'react-bootstrap';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ReservationInfo from '../../molecules/ReservationInfo/ReservationInfo'

import * as styles from './CompletePurchaseRequest.scss';
import { OnlinePurchaseSchedule } from '../../../types/onlinePurchaseSchedule';
import { ReservationDataContext } from '../../../providers/ReservationContextProvider';

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

const Component: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<{
    onlinePurchaseSchedule: OnlinePurchaseSchedule,
    purchaseRequestId: string,
    hashedScheduleId: string,
    assessmentKpiId: string
  }>();
  const reservationData = React.useContext(ReservationDataContext)
  const [invokingCancelApi, setInvokingCancelApi] = React.useState(false)

  const cancelReservation = async () => {
    setInvokingCancelApi(true)

    try {
      await axios.delete(
        `${process.env.API_BASE_URL}/api/web-app/purchase-requests/cancel`,
        {
          data: {
            purchaseRequestId: state.purchaseRequestId,
            hashedScheduleId: state.hashedScheduleId,
          }
        }
      )

      history.push('/purchase-request/canceled', {
        startAt: state.onlinePurchaseSchedule.startAt,
        selectedQty: reservationData.selectedQty,
        remarks: reservationData.remarks,
      })
    } catch (e) {
      alert('エラーが発生しました。\n既にキャンセル済みの可能性があります。')
      setInvokingCancelApi(false)
    }
  }

  React.useLayoutEffect(() => {
    if (!state?.onlinePurchaseSchedule || !state?.purchaseRequestId || !state?.hashedScheduleId) {
      alert('無効な操作です。お手数ですが、最初からやり直してください。')
      history.push('/purchase-request/schedules')
      return;
    }

    // GTMデータレイヤーに変数の値とカスタムイベントを登録
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      assessmentKpiId: state.assessmentKpiId,
      event:           "PurchaseRequestComplete",
    })
  }, []);

  return (
    <Container className={styles.webappContainer}>
      <Helmet>
        <title>予約完了 | ブランディアBell</title>
      </Helmet>

      <h5 className={`mt-5 ${styles.boldText}`}>
        予約
        {reservationData.beforeRequestId && '変更'}
        完了しました。
      </h5>

      <p className="my-4">
        ご登録いただいた電話番号にショートメール(SMS)をお送りしましたので、ご確認をお願いいたします。
        {reservationData.beforeRequestId && (
          <>
            <br />※変更前の日時はキャンセル済みです。
          </>
        )}
      </p>

      <ReservationInfo
        startAt={new Date(state?.onlinePurchaseSchedule.startAt)}
        selectedQty={reservationData?.selectedQty}
        remarks={reservationData?.remarks}
      />

      <a
        className={`mt-4 mb-2 pb-3 pt-3 btn-block text-center text-decoration-none ${styles.button}`}
        href="sms:Bell"
      >
        ショートメールを確認する <i className="fa fa-external-link" aria-hidden="true"></i>
      </a>

      <div className="text-center my-4">
        <Button
          className={styles.backBtn}
          type="button"
          variant="link"
          disabled={invokingCancelApi}
          onClick={() => {
            if (confirm('本当にキャンセルしますか？')) {
              cancelReservation()
            }
          }}
        >
          {invokingCancelApi ? 'キャンセル中・・・' : 'キャンセルする'}
        </Button>
      </div>
    </Container>
  );
};

export default Component;
