import * as React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import PurchaseItemForm from '../../molecules/InputPurchaseItemForm/InputPurchaseItemForm';
import stepPurchasedItem from '../../../assets/images/steps/reservation3.png'
import changeStepPurchasedItem from '../../../assets/images/steps/chengeReservation2.png'
import * as styles from './InputPurchasedItemPage.scss';
import SelectedPurchaseItemEntry from "../../molecules/SelectedPurchaseItemEntry/SelectedPurchaseItemEntry";
import SelectedPurchaseRequestEntry from "../../molecules/SelectedPurchaseRequestEntry/SelectedPurchaseRequestEntry";
import {ReservationDataContext} from "../../../providers/ReservationContextProvider";
const Component: React.FC = () => {
  const { isChangeReservation } = React.useContext(ReservationDataContext);

  return (
    <Container className={styles.webappContainer}>
      <Helmet>
        <title>査定品情報入力 | ブランディアBell</title>
      </Helmet>
      <Row className={`justify-content-center`}>
        {isChangeReservation
            ? <Image className={styles.headerStep} src={changeStepPurchasedItem} rounded/>
            : <Image className={styles.headerStep} src={stepPurchasedItem} rounded />
        }
      </Row>
      <SelectedPurchaseItemEntry/>
      <SelectedPurchaseRequestEntry/>

      <Row className="p-3 pb-4 mt-4 justify-content-md-center">
        <Col md={{ span: 7 }} lg={{ span: 10 }}>
          <PurchaseItemForm />
        </Col>
      </Row>
    </Container>
  );
};

export default Component;
