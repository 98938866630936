import React from 'react';
import qs from 'query-string';
import axios from 'axios';
import { Button, Spinner, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import checkBox from 'line-app/src/assets/images/checkBox.png';
import checkedBox from 'line-app/src/assets/images/checkedBox.png';

import * as styles from './PurchaseDetailsConsentPage.scss';

const Component: React.FC = () => {
  const history = useHistory();

  // 制御用用State
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isConsentChecked, setIsConsentChecked] = React.useState<boolean>(false);

  // 買取画像表示用URL一覧
  const [purchaseImageUrls, setPurchaseImageUrls] = React.useState<string[]>([]);

  // APIリクエスト時のエラーハンドリング
  const handleApiError = (): void => {
    alert('エラーが発生しました。\n再度ページをお開きいただくか、LINE上でお問い合わせください。')
    history.goBack()
  }

  // 買取画像表示用URL一覧取得
  const getPurchaseImageUrls = async (
    purchaseFormId: string,
    formTypeId: string,
    hashedPurchaseRequestId: string
  ) => {
    try {
      const { data } = await axios.get<string[]>(
        `${process.env.API_BASE_URL}/api/web-app/purchase-forms/${purchaseFormId}/images?formTypeId=${formTypeId}&hashedRequestId=${hashedPurchaseRequestId}`,
      )

      // 画像が存在しないのは例外
      if (data.length === 0) {
        throw 400;
      }

      setPurchaseImageUrls(data)
    } catch (e) {
      handleApiError()
    }
  }

  React.useEffect(() => {
    ;(async () => {
      const purchaseFormIdParam = qs.parse(location.search).formId as string | null;
      const purchaseFormTypeIdParam = qs.parse(location.search).formTypeId as string | null;
      const hashedPurchaseRequestIdParam = qs.parse(location.search).requestId as string | null;

      if (!purchaseFormIdParam || !purchaseFormTypeIdParam || !hashedPurchaseRequestIdParam) {
        return handleApiError()
      }

      await getPurchaseImageUrls(purchaseFormIdParam, purchaseFormTypeIdParam, hashedPurchaseRequestIdParam)
      setIsLoading(false)
    })()
  }, []);

  return (
    <Container className={styles.webappContainer}>
      {
        isLoading && (
          <div className={styles.screenOverlay}>
            <Spinner animation="border" variant="primary" />
          </div>
        )
      }

      <h4 className={`text-left mt-5 mb-4 ${styles.boldText}`}>次のお取引内容に同意しますか？</h4>

      <div>
        {purchaseImageUrls.map((imageUrl, index) => (
          <div key={index} className="mb-4">
            <img className="w-100" src={imageUrl} alt={`買取画像${index+1}`} />
          </div>
        ))}
      </div>

      <div className="d-flex align-items-center mt-3">
        <img
          className={styles.checkBox}
          src={isConsentChecked ? checkedBox : checkBox}
          onClick={()=> setIsConsentChecked(prev => !prev)}
        />
        <p className="mb-0">
          こちらのお取引内容に同意します。
        </p>
      </div>

      <Button
        type="button"
        className={`mt-4 mb-2 pb-3 pt-3 btn-block ${styles.button}`}
        variant="light"
        disabled={purchaseImageUrls.length === 0 || !isConsentChecked} // 画像読み込み, 同意チェック
        onClick={() => history.push(
          `/purchase-forms/input${location.search}`,
          {
            isConsentChecked: true
          }
        )}
      >
        買取情報入力へ進む
      </Button>
    </Container>
  )
};

export default Component;
