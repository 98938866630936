import * as React from 'react';
import { Container } from 'react-bootstrap';

import * as styles from './Loading.scss';

const Elm = (
  <Container fluid className={`${styles.container}`}>
    <p className={`text-center text-white ${styles.loadingSentence}`}>
      Loading...
    </p>
    <p className={`text-center text-white ${styles.loadingSentenceJa}`}>
      ページを読み込んでいます...
    </p>
  </Container>
);

export default Elm;
