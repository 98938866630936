import { BranchBank, DataBank, SelectedBranchBank, SelectedCodeBank, SelectedDataBank } from "line-app/src/types/types";


export const convertToDataBankFromAPI = (apiData: DataBank[]):SelectedDataBank[] => {
    if (!apiData) {
      return [];
    }

    return apiData.map((item: { name: string; code: string; }) => ({
      value: item.name,
      label: item.name,
      data: item.code,
    }));
  };

export const convertToDataBranchBankFromAPI = (apiData: BranchBank[]):SelectedBranchBank[] => {
  if (!apiData) {
    return [];
  }

  return apiData.map((item: { name: string; code: string; }) => ({
    value: item.name,
    label: item.name,
    data: item.code,
  }));
};

export const convertToDataCodeBankFromAPI = (apiData: BranchBank[]):SelectedCodeBank[] => {
  if (!apiData) {
    return [];
  }

  return apiData.map((item: { name: string; code: string; }) => ({
    value: item.code,
    label: item.code,
    name: item.name,
  }));
};