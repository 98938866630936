import * as React from 'react';
import {
  Container,
  Button,
} from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ReservationInfo from '../../molecules/ReservationInfo/ReservationInfo'

import * as styles from './CanceledPurchaseRequest.scss';

const Component: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<{
    startAt: string
    remarks: string
    selectedQty: number
  }>();

  React.useLayoutEffect(() => {
    if (!state?.startAt || state?.selectedQty === 0) {
      alert('無効な操作です。お手数ですが、最初からやり直してください。')
      history.push('/purchase-request/schedules')
    }
  }, []);

  return (
    <Container className={styles.webappContainer}>
      <Helmet>
        <title>キャンセル完了 | ブランディアBell</title>
      </Helmet>

      <h5 className={`mt-5 ${styles.boldText}`}>キャンセルしました。</h5>

      <ReservationInfo
        startAt={new Date(state?.startAt)}
        selectedQty={state?.selectedQty}
        remarks={state?.remarks}
      />

      <a
        className={`mt-4 mb-2 pb-3 pt-3 btn-block text-center text-decoration-none ${styles.button}`}
        href="/bell/purchase-request/schedules"
      >
        再予約する
      </a>

      <div className="text-center my-4">
        <Button
          className={styles.backBtn}
          type="button"
          variant="link"
          onClick={() => {
            location.href = 'https://brandear.jp/bell'
          }}
        >
          トップへ
        </Button>
      </div>
    </Container>
  );
};

export default Component;
