import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import Loading from 'line-app/src/components/pages/Loading/Loading'
import ScrollToTop from 'line-app/src/components/ScrollToTop'
import BellLogo from '../src/images/Bell_logo_A.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import * as styles from '../src/styles/styles.scss';

import InputPurchaseItemPage from './components/pages/InputPurchasedItemPage/InputPurchasedItemPage'
import SelectPurchaseSchedulePage from './components/pages/SelectPurchaseSchedulePage/SelectPurchaseSchedulePage'
import RegisterMobilePhonePage from './components/pages/RegisterMobilePhonePage/RegisterMobilePhonePage'
import RegisterMobilePhonePageForRealtime from './components/pages/RegisterMobilePhonePageForRealtime/RegisterMobilePhonePageForRealtime'
import VerifyMobilePhonePage from './components/pages/VerifyMobilePhonePage/VerifyMobilePhonePage'
import PurchaseDetailPage from './components/pages/PurchaseDetailPage/PurchaseDetailPage'
import ConfirmReservationInfoPage from './components/pages/ConfirmReservationInfoPage/ConfirmReservationInfoPage'
import CompletePurchaseRequest from './components/pages/CompletePurchaseRequest/CompletePurchaseRequest'
import CompleteRealtimeRequest from './components/pages/CompleteRealtimeRequest/CompleteRealtimeRequest'
import CanceledPurchaseRequest from './components/pages/CanceledPurchaseRequest/CanceledPurchaseRequest'
import ConfirmCancelPurchaseRequestPage from './components/pages/ConfirmCancelPurchaseRequestPage/ConfirmCancelPurchaseRequestPage'
import ConfirmChangePurchaseRequestPage from './components/pages/ConfirmChangePurchaseRequestPage/ConfirmChangePurchaseRequestPage'
import PurchaseDetailsConsentPage from './components/pages/PurchaseDetailsConsentPage/PurchaseDetailsConsentPage'
import NotFound from './components/pages/NotFound/NotFound'

import ReservationContextProvider from './providers/ReservationContextProvider';

// GTM implementation
if (process.env.GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

// entry point
const init = async () => {
  const ContainerWrapping = (children: React.ReactElement) => (
    <React.Suspense fallback={Loading}>
      <Helmet>
        <title>ブランディアBell オンライン店舗</title>
      </Helmet>
      <div className="text-center">
        <img src={BellLogo} className={styles.logo} alt="logo"/>
      </div>
      <hr className={styles.lineTop} />
      {children}
      <footer className="mt-5 mb-5 text-center">
        <div className="mb-4">
            <a href="https://brandear.jp/ct/kiyaku_riyou#tab3" target="_blank" className={`mr-5 ${styles.footerLink}`}>利用規約</a>
            <a href="https://brandear.jp/ct/policy" target="_blank" className={styles.footerLink}>個人情報保護方針</a>
        </div>
        <div className={styles.bodyFooter}>
            <div>株式会社デファクトスタンダード 東京都公安委員会 第302170407755号</div>
            <div>Copyright © Defactostandard, Ltd. All rights reserved.</div>
        </div>
      </footer>
    </React.Suspense>
  );

  const app = ContainerWrapping(
    <ReservationContextProvider >
      <BrowserRouter basename="/bell">
        <ScrollToTop />
        <Switch>
          {/* 通常予約用ルート */}
          <Route exact path="/purchase-request/items" component={InputPurchaseItemPage} />
          <Route exact path="/purchase-request/schedules" component={SelectPurchaseSchedulePage} />
          <Route exact path="/purchase-request/sms-auth" component={RegisterMobilePhonePage} />
          <Route exact path="/purchase-request/sms-verify" component={VerifyMobilePhonePage} />
          <Route exact path="/purchase-request/confirm" component={ConfirmReservationInfoPage} />
          <Route exact path="/purchase-request/complete" component={CompletePurchaseRequest} />
          <Route exact path="/purchase-request/canceled" component={CanceledPurchaseRequest} />
          <Route exact path="/purchase-request/:purchaseRequestId/cancelConfirm" component={ConfirmCancelPurchaseRequestPage} />
          <Route exact path="/purchase-request/:purchaseRequestId/changeConfirm" component={ConfirmChangePurchaseRequestPage} />

          {/* いまスグ予約用ルート */}
          <Route exact path="/realtime-request/sms-auth" component={RegisterMobilePhonePageForRealtime} />
          <Route exact path="/realtime-request/sms-verify" component={VerifyMobilePhonePage} />
          <Route exact path="/realtime-request/complete" component={CompleteRealtimeRequest} />

          {/* 買取情報入力 */}
          <Route exact path="/purchase-forms/consent" component={PurchaseDetailsConsentPage} />
          <Route exact path="/purchase-forms/input" component={PurchaseDetailPage} />

          {/* 404 */}
          <Route component={NotFound}  />
        </Switch>
      </BrowserRouter>
    </ReservationContextProvider>
  );

  // React描画
  ReactDOM.render(app, document.getElementById('app'));
};

init();
