// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-styles-styles__logo--Nn9bz{width:14rem;padding-top:1rem}.src-styles-styles__lineTop--3qSeA{border-color:rgba(0,0,0,.1607843137);border-width:5px}a.src-styles-styles__footerLink--2gqFk{text-decoration:underline;color:#314b57;font-size:15px}.src-styles-styles__bodyFooter--O8idj{font-size:10px;color:#314b57}", ""]);
// Exports
exports.locals = {
	"logo": "src-styles-styles__logo--Nn9bz",
	"lineTop": "src-styles-styles__lineTop--3qSeA",
	"footerLink": "src-styles-styles__footerLink--2gqFk",
	"bodyFooter": "src-styles-styles__bodyFooter--O8idj"
};
module.exports = exports;
