import React from 'react';
import { Container, Form, Button, Row, Col, CloseButton } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import DatePicker, { registerLocale } from "react-datepicker";
import Select, {DropdownIndicatorProps, components,MultiValue,SingleValue } from 'react-select'

import * as styles from './PurchaseDetailForm.scss';
import {
  AccountInfo,
  JobEnum,
  PickupDateTime,
  PickupTimeFrameEnum,
  ReferralFriend,
  UserInfo,
  BusinessInvoice,
  DataBank,
  BranchBank,
  SelectedDataBank,
  SelectedBranchBank,
  SelectedCodeBank,
  AddressWithPostcode
} from '../../../types/types';

import ja from 'date-fns/locale/ja'
import "react-datepicker/dist/react-datepicker.css";

import checkBox from '../../../assets/images/checkBox.png';
import checkedBox from '../../../assets/images/checkedBox.png';

import { convertKanaF2H, convertToDataBankFromAPI, convertToDataBranchBankFromAPI, convertToDataCodeBankFromAPI} from "../../../../../packages/webapp/src/utils"
import {PurchaseDetailNameSpace} from "../../../types/enums";

type Props = {
  accountInfo: AccountInfo,
  jobEnum: JobEnum | any,
  pickupDateTime: PickupDateTime,
  pickupTimeFrameEnum: PickupTimeFrameEnum | any,
  referralFriend: ReferralFriend,
  userInfo: UserInfo,
  isPermittedMailMagazine: boolean,
  businessInvoice: BusinessInvoice,
  submitDisabled: boolean,
  isPurchaseForm3: boolean,
  isPurchaseForm6: boolean,
  setAccountInfo: React.Dispatch<React.SetStateAction<AccountInfo>>,
  setPickupDateTime: React.Dispatch<React.SetStateAction<PickupDateTime>>,
  setReferralFriend: React.Dispatch<React.SetStateAction<ReferralFriend>>,
  setBusinessInvoice: React.Dispatch<React.SetStateAction<BusinessInvoice>>,
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo>>,
  setIsPermittedMailMagazine: React.Dispatch<React.SetStateAction<boolean>>,
  getDatesFromTomorrowToNthDays: (maxDays: number) => Date[],
  getPartialAddressFromPostcodeAPI: (postcode: string) => Promise<AddressWithPostcode|null>
  dataBank: DataBank[],
  branchBank: BranchBank[],
  codeBank: BranchBank[],
  handleChangeBank: (newValue: MultiValue<SelectedDataBank> | SingleValue<SelectedDataBank>) => void;
  handleChangeBankBranch: (newValue: MultiValue<SelectedBranchBank> | SingleValue<SelectedBranchBank>) => void,
  handleChangeBankCode: (newValue: MultiValue<SelectedCodeBank> | SingleValue<SelectedCodeBank>) => void,
  selectedOptionBankName: SelectedDataBank | null,
  selectedOptionBankBranch: SelectedBranchBank | null,
  selectedOptionBankCode: SelectedCodeBank | null,
  disabledOption: (value: string) => boolean,
  handlePurchaseDetailForm: () => Promise<void>,
  loadingPartialAddressFromPostcodeAPI: boolean,
  setLoadingPartialAddressFromPostcodeAPI: React.Dispatch<React.SetStateAction<boolean>>,
  inputBankName: string,
  setInputBankName: (value:string) => void
  handleInputBankName: (value: string) => void,
  inputBranchNumber: string,
  handleInputBranchNumber: (value:string) => void
  inputBranchName: string
  handleInputBranchName: (value:string) => void,
}

const DropdownIndicator = ( props: DropdownIndicatorProps<any, true>) => {
  const combinedClasses = `${styles.customDropdownIconPurchaseDetail} fa fa-caret-down`
  return (
      <components.DropdownIndicator {...props}>
        <i className={combinedClasses}></i>
      </components.DropdownIndicator>
  );
};

const customStylesSelect2 = {
  control: (base: any, state: any) => ({
    ...base,
    height: '2.5rem',
    borderWidth: "1px",
    borderColor: '#314B57',
    backgroundColor: (state.selectProps.value || state.selectProps.inputValue) ? '#fff' : '#eef3f3',
    "&:hover": {
      borderColor: '#314B57',
    },
    "&:focus-within": {
      backgroundColor: '#fff',
      borderColor: '#25A0CEFF',
      boxShadow: "0 0 8px #25A0CEF",
    },
  }),
  option: (provided: any, state: { isFocused: any; }) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#1967D2' : 'white',
    color: state.isFocused ? 'white' : '#314B57',
    '&:hover': {
      backgroundColor: '#1967D2',
      color: 'white',
    },
  }),
};
registerLocale('ja', ja) // react-datepickerの日本語化

// 買取情報入力フォーム（form_type_id: 1,3）
const Component: React.FC<Props> = ({
  accountInfo,
  jobEnum,
  pickupDateTime,
  pickupTimeFrameEnum,
  referralFriend,
  businessInvoice,
  userInfo,
  isPermittedMailMagazine,
  submitDisabled,
  isPurchaseForm3,
  isPurchaseForm6,
  setAccountInfo,
  setPickupDateTime,
  setReferralFriend,
  setUserInfo,
  setIsPermittedMailMagazine,
  setBusinessInvoice,
  getDatesFromTomorrowToNthDays,
  getPartialAddressFromPostcodeAPI,
  dataBank,
  branchBank,
  codeBank,
  handleChangeBank,
  handleChangeBankBranch,
  handleChangeBankCode,
  selectedOptionBankName,
  selectedOptionBankBranch,
  selectedOptionBankCode,
  disabledOption,
  handlePurchaseDetailForm,
  loadingPartialAddressFromPostcodeAPI,
  setLoadingPartialAddressFromPostcodeAPI,
  inputBankName,
  setInputBankName,
  handleInputBankName,
  inputBranchNumber,
  handleInputBranchNumber,
  inputBranchName,
  handleInputBranchName,
}) => {
  const [isPostCodeError, setIsPostCodeError] = React.useState<boolean>(false);
  return (
    <Container>
      <Helmet>
        <title>買取情報入力</title>
      </Helmet>
      <h4 className={`pt-5 pb-3 text-center`}><b>買取情報入力</b></h4>

      <div className="text-center mb-5">
        必須項目を全て入力し、「入力確認画面」へお進みください
      </div>

      <h5 className={styles.customSubTitle}>
        ご本人様情報
      </h5>

      <Form className="flex-fill" noValidate>
        {!isPurchaseForm3 && (
          <>
            <Form.Label className="font-weight-bold">
              お名前（本人確認書類と同一表記）<span className={styles.red}>必須</span>
            </Form.Label>
            <Form.Group as={Row} controlId="user_name">
              <Col sm="6">
                <Form.Label className="font-weight-bold">
                姓
                </Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="山田"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({
                      ...userInfo,
                      userSurname: e.target.value
                    })}
                    value={userInfo.userSurname}
                    className={styles.formText}
                    maxLength={50}
                />
              </Col>
              <Col sm="6">
                <Form.Label className="font-weight-bold">
                  名
                </Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="太郎"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({
                      ...userInfo,
                      userName: e.target.value
                    })}
                    value={userInfo.userName}
                    className={styles.formText}
                    maxLength={50}
                />
              </Col>
            </Form.Group>
            <Form.Label className="font-weight-bold">
              お名前（全角フリガナ）<span className={styles.red}>必須</span>
            </Form.Label>
            <Form.Group as={Row} controlId="user_name">
              <Col sm="6">
                <Form.Label className="font-weight-bold">
                  セイ
                </Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="ヤマダ"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({
                      ...userInfo,
                      userSurnameKana: e.target.value
                    })}
                    value={userInfo.userSurnameKana}
                    className={styles.formText}
                    maxLength={50}
                />
              </Col>
              <Col sm="6">
                <Form.Label className="font-weight-bold">
                  メイ
                </Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="タロウ"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({
                      ...userInfo,
                      userNameKana: e.target.value
                    })}
                    value={userInfo.userNameKana}
                    className={styles.formText}
                    maxLength={50}
                />
              </Col>
            </Form.Group>
            <Form.Label className="font-weight-bold">
              ご住所<span className={styles.red}>必須</span>
            </Form.Label>
          </>
        )}
        {!isPurchaseForm3 && (
          <>
            <Form.Group as={Row} controlId="postcode">
              <Col sm="4" xs="12">
                <Form.Label className="font-weight-bold">
                  郵便番号（ハイフンなし）
                </Form.Label>
              </Col>
              <Col sm="3" xs="6">
                <Form.Control
                    required
                    type="text"
                    placeholder="00000000"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setUserInfo({
                        ...userInfo,
                        postcode: e.target.value,
                        address: '',
                        address1: '',
                        address2: ''
                      });
                    }}
                    onInput={() => { setIsPostCodeError(false) }}
                    defaultValue={userInfo.postcode}
                    className={styles.formText}
                    maxLength={7}
                    pattern="\d{7}"
                />
              </Col>
              <Col sm="3" xs="5">
                <div className="d-flex">
                  <Button
                      variant="dark"
                      className={`${styles.customBackgroundButtonPostcode} flex-grow-1`}
                      onClick={async () => {
                        setLoadingPartialAddressFromPostcodeAPI(true)
                        const postcode = userInfo.postcode
                        const partialAddress = await getPartialAddressFromPostcodeAPI(postcode)
                        if(!partialAddress)
                        {
                          setIsPostCodeError(true)
                        }
                        else {
                          setUserInfo({
                            ...userInfo,
                            address: partialAddress?.allAddress ?? '',
                            address1: '',
                            address2: ''
                          });
                        }
                        setLoadingPartialAddressFromPostcodeAPI(false)
                      }}
                      disabled={loadingPartialAddressFromPostcodeAPI}
                  >
                    住所入力
                  </Button>
                </div>
              </Col>
              <Col sm="2" xs="1"></Col>
            </Form.Group>
            <Form.Label className={`text-secondary ${styles.customSubTitleForAddressInput}`} id='partialAddress'>
              {userInfo?.address || (isPostCodeError ? '郵便番号が正しくありません。もう一度入力してください。' : '郵便番号を入力し、住所入力ボタンを押してください。') }
            </Form.Label>
            <Form.Group controlId="address">
              <Form.Control
                  required
                  type="hidden"
                  placeholder="都道府県＆市区町村＆町域"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({ ...userInfo, address: e.target.value })}
                  value={userInfo?.address ?? ''}
                  className={styles.formText}
                  maxLength={255}
              />
            </Form.Group>
            <Form.Group controlId="address">
              <Form.Control
                  required
                  type="text"
                  placeholder="番地"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({ ...userInfo, address1: e.target.value })}
                  value={userInfo?.address1 ?? ''}
                  className={styles.formText}
                  maxLength={255}
              />
            </Form.Group>
            <Form.Group controlId="address">
              <Form.Control
                required
                type="text"
                placeholder="マンション名・ビル名"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({ ...userInfo, address2: e.target.value })}
                value={userInfo?.address2 ?? ''}
                className={styles.formText}
                maxLength={255}
              />
            </Form.Group>
          </>
        )}

        <Form.Group controlId="birthday">
          <Form.Label className="font-weight-bold">
            生年月日<span className={styles.red}>必須</span>
          </Form.Label><br />
          <DatePicker
            required
            placeholderText="2000/01/01"
            dateFormat="yyyy/MM/dd"
            selected={userInfo.birthday ? new Date(userInfo.birthday) : null}
            onChange={(date: Date) => setUserInfo({ ...userInfo, birthday: date.toLocaleDateString() })}
            onFocus={e => e.target.blur()}
            locale="ja"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            openToDate={new Date("1990/01/01")}
            minDate={new Date('1900/01/01')}
            maxDate={new Date()}
            dropdownMode="select"
            className={`form-control ${styles.formText}`}
            wrapperClassName="d-block"
          />
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label className="font-weight-bold">
            メールアドレス<span className={styles.red}>必須</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="brandear@brandear.co.jp"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({ ...userInfo, email: e.target.value })}
            value={userInfo.email}
            className={styles.formText}
            maxLength={255}
          />
        </Form.Group>

        <Form.Group controlId="job">
          <Form.Label className="font-weight-bold">
            ご職業<span className={styles.red}>必須</span>
          </Form.Label>
          <div className={styles.select}>
            <i
              className={`fa fa-caret-down ${styles.selectMark}`}
              aria-hidden="true"
            ></i>
            <Form.Control
              as="select"
              required
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setUserInfo({ ...userInfo, job: e.target.value })}
              className={`
                ${styles.formSelect}
                ${userInfo.job ? styles.selected : styles.unselected}
              `}
            >
              <option key="" value="">
                選択
              </option>
              {
                Object.keys(jobEnum).map(key => (
                  <option key={key} value={key} selected={key === userInfo.job}>
                    {jobEnum[key]}
                  </option>
                ))}
            </Form.Control>
          </div>
        </Form.Group>
        {userInfo.job === PurchaseDetailNameSpace.JobEnum.OTHER && (
          <Form.Group controlId="detail_job_other">
            <Form.Label className="font-weight-bold">
              その他を選択された方は以下にご職業を入力ください。
            </Form.Label>
            <Form.Control
                type="text"
                placeholder="ご職業 （その他）"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({ ...userInfo, detailJobOther: e.target.value })}
                value={userInfo.detailJobOther}
                className={styles.formText}
                maxLength={255}
            />
          </Form.Group>
        )}

        <h5 className={styles.customSubTitle}>
          <div className={styles.customSubTitle1}>お口座情報</div>
          <div className={styles.customSubTitle1}>（本人確認書類と同一表記）</div>
        </h5>

        <Form.Group as={Row} controlId="bankName">
          <Form.Label className="font-weight-bold" column sm="4" md="5">
            金融機関名<span className={styles.red}>必須</span>
          </Form.Label>
          <Col sm="8" md="7">
            <Select
                inputValue={inputBankName}
                styles={customStylesSelect2}
                value={selectedOptionBankName}
                onChange={(value) => {
                  handleChangeBank(value)
                  setInputBankName('')
                }}
                onInputChange={(value, action) => {
                  if (action.action === "input-change") {
                    handleInputBankName(value)
                  }
                }}
                options={(convertToDataBankFromAPI(dataBank))}
                placeholder="例：〇〇銀行"
                components={{ DropdownIndicator,IndicatorSeparator: () => null}}
                noOptionsMessage={() => 'ヒットなし。手入力してください。'}
                isSearchable
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="branchNumber">
          <Form.Label className="font-weight-bold" column sm="4" md="5">
            支店番号<span className={styles.red}>必須</span>
          </Form.Label>
          <Col sm="8" md="7">
            <Select
              inputValue={inputBranchNumber}
              styles={customStylesSelect2}
              value={selectedOptionBankCode}
              onChange={handleChangeBankCode}
              onInputChange={(value, action) => {
                if (action.action === "input-change") {
                  handleInputBranchNumber(value)
                }
              }}
              options={(convertToDataCodeBankFromAPI(codeBank))}
              placeholder="例：123"
              components={{ DropdownIndicator,IndicatorSeparator: () => null}}
              noOptionsMessage={() => 'ヒットなし。手入力してください。'}
              isSearchable
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="branchName">
          <Form.Label className="font-weight-bold" column sm="4" md="5">
            支店名<span className={styles.red}>必須</span>
          </Form.Label>
          <Col sm="8" md="7">
            <Select
              inputValue={inputBranchName}
              styles={customStylesSelect2}
              value={selectedOptionBankBranch}
              onChange={handleChangeBankBranch}
              onInputChange={(value, action) => {
                if (action.action === "input-change") {
                  handleInputBranchName(value)
                }
              }}
              options={convertToDataBranchBankFromAPI(branchBank)}
              placeholder="例：〇〇支店"
              components={{ DropdownIndicator,IndicatorSeparator: () => null}}
              noOptionsMessage={() => 'ヒットなし。手入力してください。'}
              isSearchable
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="accountType">
          <Form.Label className="font-weight-bold" column sm="4" md="5">
            口座種別<span className={styles.red}>必須</span>
          </Form.Label>
          <div className={`${styles.select} col-sm-8 col-md-7`}>
            <i
              className={`fa fa-caret-down ${styles.selectMark} ${styles.sm}`}
              aria-hidden="true"
            ></i>
            <Form.Control
              required
              as="select"
              value={accountInfo.accountType ?? ''}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setAccountInfo({ ...accountInfo, accountType: e.target.value })}
              className={`
                ${styles.formSelect}
                ${accountInfo.accountType ? styles.selected : styles.unselected}
              `}
            >
              <option key="" value="">
                選択
              </option>
              {
                ['普通', '当座', '貯蓄']
                  .map((v: string, i: number) =>
                    <option key={i} value={v} selected={v === accountInfo.accountType}>{v}</option>
                  )
              }
            </Form.Control>
          </div>
        </Form.Group>

        <Form.Group as={Row} controlId="accountNumber">
          <Form.Label className="font-weight-bold" column sm="4" md="5">
            口座番号<span className={styles.red}>必須</span>
          </Form.Label>
          <Col sm="8" md="7">
            <Form.Control
              required
              type="text"
              placeholder="例：1234567"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccountInfo({ ...accountInfo, accountNumber: e.target.value })}
              value={accountInfo.accountNumber ?? ''}
              className={styles.formText}
              maxLength={50}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="accountName">
          <Form.Label className="font-weight-bold" column sm="4" md="5">
            お口座名義（半角フリガナ）<span className={styles.red}>必須</span>
          </Form.Label>
          <Col sm="8" md="7">
            <Form.Control
              required
              type="text"
              placeholder="例：ﾔﾏﾀﾞﾀﾛｳ"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccountInfo({ ...accountInfo, accountName: e.target.value })}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => setAccountInfo({ ...accountInfo, accountName: convertKanaF2H(e.target.value) })}
              value={accountInfo.accountName ?? ''}
              className={styles.formText}
              maxLength={50}
            />
          </Col>
        </Form.Group>

        {!isPurchaseForm3 && !isPurchaseForm6 && (
          <>
            <h5 className={styles.customSubTitle}>
              集荷ご希望日時
            </h5>

            <Form.Group controlId="pickupDate">
              <Form.Label className="font-weight-bold">
                集荷日（翌日～1週間以内）<span className={styles.red}>必須</span>
              </Form.Label>
              <div className={styles.select}>
                <i
                  className={`fa fa-caret-down ${styles.selectMark}`}
                  aria-hidden="true"
                ></i>
                <Form.Control
                  as="select"
                  required
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setPickupDateTime({ ...pickupDateTime, pickupDate: e.target.value, pickupTimeFrame: '' })}
                  className={`
                ${styles.formSelect}
                ${pickupDateTime.pickupDate ? styles.selected : styles.unselected}
              `}
                >
                  <option key="" value="">
                    選択
                  </option>
                  {
                    getDatesFromTomorrowToNthDays(7).map((date: Date, i: number) => (
                      <option
                        key={i}
                        value={`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`.replace(/\n|\r/g, '')} // ex: "2021-06-10"
                      >
                        {/* ex: 6月10日 */}
                        {`${(date.getMonth() + 1)}月${date.getDate()}日`.replace(/\n|\r/g, '')}
                      </option>
                    ))
                  }
                </Form.Control>
              </div>
            </Form.Group>

            <Form.Group controlId="pickupTimeFrame">
              <Form.Label className="font-weight-bold">
                集荷時間（①～④でご選択ください。）<span id="pickupTimeFrame" className={styles.red}>必須</span>
              </Form.Label>
              <div className={styles.select}>
                <i
                  className={`fa fa-caret-down ${styles.selectMark}`}
                  aria-hidden="true"
                ></i>
                <Form.Control
                  as="select"
                  required
                  value={pickupDateTime.pickupTimeFrame}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setPickupDateTime({ ...pickupDateTime, pickupTimeFrame: e.target.value })}
                  className={`
                ${styles.formSelect}
                ${pickupDateTime.pickupTimeFrame ? styles.selected : styles.unselected}
              `}
                >
                  <option key="" value="">
                    選択
                  </option>
                  {
                    pickupDateTime.pickupDate &&
                    Object.keys(pickupTimeFrameEnum).map(key => (
                      <option key={key} value={key} disabled={disabledOption(pickupTimeFrameEnum[key])}>
                        {pickupTimeFrameEnum[key]}
                      </option>
                    ))
                  }
                </Form.Control>
              </div>
            </Form.Group>
          </>
        )}

        <h5 className={styles.customSubTitle}>
          お友達からご紹介されたお客様
        </h5>

        <Form.Group controlId="usedDate">
          <Form.Label className="font-weight-bold">ご紹介者様のご利用日</Form.Label><br />
          <div className="d-flex pr-1">
            <DatePicker
              selected={referralFriend.usedDate ? new Date(referralFriend.usedDate) : null}
              placeholderText="2021/01/01"
              onChange={(date: Date) => setReferralFriend({ ...referralFriend, usedDate: date.toLocaleDateString() })}
              onFocus={e => e.target.blur()}
              locale="ja"
              dateFormat="yyyy/MM/dd"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              minDate={new Date('2020/07/01')}
              maxDate={new Date()}
              dropdownMode="select"
              className={`form-control ${styles.formText}`}
              wrapperClassName="d-block w-100 pr-2"
            />
            {/* 日付選択クリアボタン */}
            <CloseButton
              onClick={() => setReferralFriend({ ...referralFriend, usedDate: '' })}
            />
          </div>
        </Form.Group>

        <Form.Group controlId="friendName">
          <Form.Label className="font-weight-bold">ご紹介者様のお名前（漢字フルネーム）</Form.Label>
          <Form.Control
            type="text"
            placeholder="例：ブランディア太郎"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReferralFriend({ ...referralFriend, friendName: e.target.value })}
            value={referralFriend.friendName}
            className={styles.formText}
            maxLength={50}
          />
        </Form.Group>

        <Form.Group className="mt-4 pt-2" controlId="isPermittedMailMagazine">
          <img
            className={styles.covenantCheckBox}
            src={isPermittedMailMagazine ? checkedBox : checkBox}
            onClick={() => setIsPermittedMailMagazine((prev) => !prev)}
          />
          <span>メールでお得な情報を受取る</span>
        </Form.Group>

        <Form.Label className="font-weight-bold">適格請求書発行事業者ですか（個人でご利用のお客様は「いいえ」をお選びください)</Form.Label>
        <Form.Group className="pt-2">
          <div className={styles.covenantRadioBox}>
            <img
              className={styles.covenantCheckBox}
              src={businessInvoice.isBusinessInvoice ? checkBox : checkedBox}
              onClick={() => {
                setBusinessInvoice(prevState => ({
                  ...prevState,
                  isBusinessInvoice: false,
                  businessInvoiceRegistrationNumber: '',
                }));
              }}
            />
            <span>いいえ</span>
            <img
              className={styles.covenantCheckBox}
              src={businessInvoice.isBusinessInvoice ? checkedBox : checkBox}
              onClick={() => {
                setBusinessInvoice(prevState => ({
                  ...prevState,
                  isBusinessInvoice: true,
                  businessInvoiceRegistrationNumber: '',
                }));
              }}
            />
            <span>はい</span>
          </div>
        </Form.Group>
        {businessInvoice.isBusinessInvoice && (
          <Form.Group>
            <Form.Label className="font-weight-bold">登録番号を入力してください。</Form.Label>
            <Form.Control
                type="text"
                placeholder="例：T1234567891234"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBusinessInvoice({
                  ...businessInvoice,
                  businessInvoiceRegistrationNumber: e.target.value
                })}
                value={businessInvoice.businessInvoiceRegistrationNumber ?? ''}
                className={styles.formText}
                maxLength={14}
            />
          </Form.Group>
        )}
        <Button
          // IDはGTMでのトラッキング用に付与している
          id="btn__purchase-details--confirm"
          type="button"
          className={`mt-5 mb-2 pb-3 pt-3 btn-block ${styles.customButton} ${styles.button}`}
          variant="light"
          disabled={submitDisabled}
          onClick={handlePurchaseDetailForm}
        >
          入力確認画面へ
        </Button>
      </Form>
    </Container>
  )
}

export default Component;
