import * as React from 'react';
import { Spinner } from 'react-bootstrap'
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { OnlinePurchaseSchedule } from '../../../types/onlinePurchaseSchedule';
import { ReservationDataContext } from '../../../providers/ReservationContextProvider';
import {
  MAX_QTY_FOR_30_MINUTES_FRAME,
  MAX_QTY_FOR_60_MINUTES_FRAME,
  MAX_QTY_FOR_90_MINUTES_FRAME,
  MAX_QTY_FOR_120_MINUTES_FRAME,
} from 'line-app/src/enums/SelectablePurchaseItemsQty';

import * as styles from './SelectedPurchaseRequestEntry.scss';

const Component: React.FC = () => {
  const history = useHistory();
  const { selectedQty, scheduleId } = React.useContext(ReservationDataContext);
  const [onlinePurchaseSchedule, setOnlinePurchaseSchedule] = React.useState<OnlinePurchaseSchedule>();
  const [isLoading, setIsLoading] = React.useState(false);
  const isItemsPage = window.location.pathname === '/bell/purchase-request/items';

  React.useEffect(() => {
    setIsLoading(true);
    ;(async () => {
      try {
        const { data } = await axios.get<OnlinePurchaseSchedule>(
          `${process.env.API_BASE_URL}/api/web-app/online-purchase-schedules/${scheduleId}`
        )
        setOnlinePurchaseSchedule(data)
        setIsLoading(false);
      } catch (e) {
        if (isItemsPage) {
          return;
        }
        alert('エラーが発生しました。選択した日時が予約可能かご確認ください')
        return history.push('/purchase-request/schedules')
      }
    })()
  }, []);

  const startAt = onlinePurchaseSchedule && new Date(onlinePurchaseSchedule.startAt)

  /**
   * 例: 2023年01月13日（金）
   */
  const formatDate = (date: Date) => {
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日（${['日', '月', '火', '水', '木', '金', '土'][date.getDay()] }）`
  }

  /**
   * 数値を二桁の文字列に変換(ex: 9 -> 09)
   */
  const convertDoubleDigitStr = (n: number): string => {
    return n < 10 ? `0${n}` : String(n)
  }

  /**
   * 例: 12:00
   */
  const formatStartTime = (date: Date) => {
    return `${convertDoubleDigitStr(date.getHours())}:${convertDoubleDigitStr(date.getMinutes())}`
  }

  /**
   * 持込点数から終了時間を判断
   * 例: 12:00
   */
  const formatEndTime = (date: Date, selectedQty: number) => {
    let purchaseMinutes = 0;
    switch (selectedQty) {
      case MAX_QTY_FOR_30_MINUTES_FRAME:
        purchaseMinutes = 30;
        break;
      case MAX_QTY_FOR_60_MINUTES_FRAME:
        purchaseMinutes = 60;
        break;
      case MAX_QTY_FOR_90_MINUTES_FRAME:
        purchaseMinutes = 90;
        break;
      case MAX_QTY_FOR_120_MINUTES_FRAME:
        purchaseMinutes = 120;
        break;
    }
    date.setMinutes(date.getMinutes() + purchaseMinutes)

    return `${convertDoubleDigitStr(date.getHours())}:${convertDoubleDigitStr(date.getMinutes())}`
  }

  return (
    <div className={styles.purchaseRequestEntry}>
      {isLoading && (
        <div className={styles.screenOverlay}>
          <Spinner animation="border" variant="info" />
        </div>
      )}

      {onlinePurchaseSchedule && startAt && (
        <div className="d-flex align-items-center">
          <p className={styles.entryLabel}>日時</p>
          <p className="mb-0">
            {formatDate(startAt)} {formatStartTime(startAt)} - {formatEndTime(startAt, selectedQty)}
          </p>
        </div>
      )}
    </div>
  );
};

export default Component;
