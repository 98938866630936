import * as React from 'react';
import { ReservationDataContext } from '../../../providers/ReservationContextProvider';
import * as styles from './SelectedPurchaseItemEntry.scss';

const Component: React.FC = () => {
  const { scheduleId } = React.useContext(ReservationDataContext);

  return (
    // scheduleIdがセットされていない（デフォルト値のまま）の場合、デザインを分岐させる
    <div className={scheduleId === 0 ? styles.purchaseItemEntry : styles.purchaseItemEntryWithSchedule}>
      <div className="d-flex align-items-center">
        <p className={styles.entryLabel}>所要時間</p>
        <p className="mb-0">30 - 60分</p>
      </div>
    </div>
  );
};

export default Component;
