import * as React from 'react';

import * as styles from './CovenantCheck.scss';

import checkBox from 'line-app/src/assets/images/checkBox.png';
import checkedBox from 'line-app/src/assets/images/checkedBox.png';

type Props = {
  checked: boolean,
  setChecked: React.Dispatch<React.SetStateAction<boolean>>,
  checkedAsp: boolean,
  setCheckedAsp: React.Dispatch<React.SetStateAction<boolean>>,
}

const Component: React.FC<Props> = ({
  checked,
  setChecked,
  checkedAsp,
  setCheckedAsp,
}) => {
  const [showCovenant, setShowCovenant] = React.useState<boolean>(false)
  const [showCovenantAsp, setShowCovenantAsp] = React.useState<boolean>(false)

  return (
    <div className="mt-4">
      <hr className={`${styles.covenantHr} m-0`} />
      <div
        className={styles.aboutCovenant}
        onClick={() => setShowCovenant((prev) => !prev)}
      >
        <p className="mb-0">利用規約について</p>
        <span>
          {showCovenant ? 'ー' : '＋'}
        </span>
      </div>

      {showCovenant && (
        <div className={styles.detailWrapper}>
          <div>
            <h4>Brandear（ブランディア）ご利用規約</h4>

            <p className="mt-3 mb-4">
              本ご利用規約（以下「本規約」）は、株式会社デファクトスタンダード（以下「当社」）が提供するブランディア買取サービスに関するすべてのサービス（以下「本サービス」といい、本サービスを提供するウェブサイトを「本サイト」といいます。）における利用条件を定めるものです。
              ご利用者様は本規約に従って本サービスをご利用いただくものとします。また、ご利用者様は、第2条に定めるユーザー登録手続完了時点で本規約に同意したものと致します。
            </p>

            <div className={styles.item}>
              <h5>第１条： （目的）</h5>
              <p>本規約は、当社と第2条に定義するユーザー資格を有するご利用者様との間で、お取引するにあたり、当事者間の権利義務関係を明確にすることを目的とします。</p>
            </div>

            <div className={styles.item}>
              <h5>第２条： （ユーザー資格）</h5>
              <p>
                ユーザー資格とは、ご利用者様がインターネットを使って当社が運営する本サイトの利用において当社が申込の勧誘をする商品、権利、デジタルコンテンツおよびサービス（以下「商品等」）の購入および申込（本サービスの提供契約の締結を含むものとします。）をするために必要となる資格をいい、
                ご利用者様は所定のユーザー登録完了後または登録時にユーザー資格を取得するものとします。また会員規約（https://brandear.jp/ct/kiyaku_kaiin）に基づきアカウントの登録を行った会員である場合も同様の資格を有します。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第３条： （ユーザーIDおよびパスワードの管理）</h5>
              <p>
                ユーザーIDおよびパスワードは、他人に知られることがないよう定期的に変更する等、ご利用者様本人が責任をもって管理してください。入力されたユーザーIDおよびパスワードが登録されたものと一致することを所定の方法により確認した場合、
                会員による利用があったものとみなし、それらが盗用、不正使用その他の事情により会員以外の者が利用している場合であっても、それにより生じた損害について当社は一切責任を負いません。ただし、当社の管理に起因する場合はその限りではありません。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第４条： （登録情報の変更）</h5>
              <p>
                ご利用者様が当社に登録したユーザー情報に変更が生じた場合は、速やかに変更登録をお願いいたします。
                変更登録がなされなかったことにより生じた損害について、当社は一切責任を負いません。
                また、変更登録がなされた場合でも、変更登録前にすでに手続がなされた取引は、変更登録前の情報に基づいて行われます。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第５条： （査定のお品物の梱包）</h5>
              <p>
                ご利用者様によりお品物を梱包、お送りいただく際、お品物は破損等発生しないように最善の方法で梱包をしていただきます。
                明らかに十分ではない梱包状態であると判断される場合、梱包資材の強度等に問題がある場合またはお品物に破損があった場合には当社にて保障は致しかねますのでご了承ください。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第６条： （査定について）</h5>
              <p>
                1. 本サービスでは、買取させていただくお品物の現物を実際に触り拝見した上で査定させていただきます。<br />
                2. 買い取りご依頼のお品物が未開封や新品の場合でも、動作確認・物品確認の為に開封致します。その為、開封後に買取りご依頼のお品物の返却が発生した場合でも、開封等についての責任は一切負いかねます。<br />
                3. お品物個数とは、ご利用者様にお送りいただいたお品物の個数をいい、査定の際に当社にて個数を数えさせていただいた時点で確認した個数と致します。<br />
                4. 査定金額とは、そのお品物の付属品等を全てを含む価値と致します。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第７条： （取引の前提）</h5>
              <p>
                1. お取引の前提としてメーカー製品またはお品物に記載されているブランド社製のものであることを前提とします。当該前提が充足されていない場合、当社は、本規約第16条に定める買取契約（以下「買取契約」）の締結前であれば直ちに当該メーカー製品またはお品物を返却させていただき、買取契約の締結後は催告することなく直ちに契約を解除することができるものとします。<br />
                2. 貴金属・地金・宝飾品等の刻印されている内容、証明書に記載されている内容と実物のお品物とに差異がある場合、当社は、買取契約の締結前であれば直ちに当該お品物を返却させていただき、買取契約の締結後は催告することなく直ちに契約を解除することができるものとします。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第８条： （本人確認について）</h5>
              <p>
                1. 古物営業法により、買取取引をする際、必要に応じて次の方法でのご本人様確認、代金の振込をさせていただきます。<br />
                <strong>「集荷サービス等を利用し本人確認書類（運転免許証等 ※1）と集荷サービスを用いた取引の場合」</strong><br />
                ・宅配キット送付先と本人確認書類に記載されている住所が一致している必要があります。<br />
                ・本人確認書類の名義と振込口座名義が一致している必要があります。<br />
                <strong>「住民票の写し等（※2）を用いた取引の場合」</strong><br />
                ・住民票記載のご本人名義の口座に入金<br />
                <strong>「e-kyc（オンライン本人確認）を利用し運転免許証を用いた取引の場合」</strong><br />
                ・オンライン本人確認によって運転免許証とご本人様顔写真を照合し、判定が正常に行われる必要があります。<br />
                （住所不一致の場合は補完書類 ※3 を用いて追加確認実施）<br />
                <strong>※1 運転免許証等とは、以下のもので現住所の記載があるものとします。</strong><br />
                （全て、有効期限内のもの、住所変更の届出が済んでいるものに限る）<br />
                ・運転免許証<br />
                ・旅券（パスポート）<br />
                ・健康保険証<br />
                ・官公庁および特殊法人の本人確認書類で写真付のもの<br />
                ・外国人登録証明書（特別永住者証明書）<br />
                <strong>※2 住民票の写し等</strong>とは、以下のものを指します。<br />
                ・住民票の記載事項証明書<br />
                ・戸籍の謄本、もしくは抄本（戸籍の附票の写しが添付されているものに限る）<br />
                ・印鑑証明書<br />
                ・外国人登録原票の写し<br />
                ・外国人登録原票の記載事項証明書<br />
                <strong>※3 補完書類</strong>とは、以下のものを指します。<br />
                ・発行より3ヶ月以内のガス・電気・水道いずれかの公共料金の領収書<br />
                2. マイナンバーが誤って当社に送られて来た場合には破棄致します。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第９条： （18歳未満の方のご利用について）</h5>
              <p>
                18歳未満の方は、本サービスをご利用いただくことは出来ません。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第１０条： （送料について）</h5>
              <p>
                1. 当社がウェブサイト、携帯サイト、宅配キットのご案内であらかじめ買取できない旨掲示しているお品物についてお送りいただいた場合には往復の送料、資材等の費用をいただく場合があります。<br />
                2. 当社がご案内する方法以外での配送や配送業者の利用があった場合（空港郵便や保険を含みますが、これらに限りません。）には送料等の費用をいただく場合がございます。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第１１条： （いたずら申込）</h5>
              <p>
                1. お品物が入っていない状態で送っていただいた場合にはいたずら申込と判断し片道送料に手間賃3000円を加えた合計金額を請求させていただきます。内容証明郵便等別途郵便代が発生した場合にもその金額を請求させていただきます。<br />
                2. 不正確な住所または架空の住所によるお申込みがあった場合には、当社で発生した費用一切を請求させていただく場合があります。<br />
                3. お申込みいただいた宅配キットを他の用途にご利用いただいた場合には、当社で発生した費用一切を請求させていただく場合があります。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第１２条： （ご入金等について）</h5>
              <p>
                1. 当社から査定金額を提示させていただき、ご利用者様からの同意が確認できた後、原則3営業日以内にお支払致します。支払方法につきましては、ご利用者様は、ご利用者様の指定する金融機関の口座宛に現金でお振り込みを行うか、またはご利用者様に別途お申込みいただいたブランディアポイント口座に対して当社が発行するブランディアポイント（以下「ポイント」）の付与を行うかのいずれかを選択（以下「支払方法選択」）できるものとします。（ご利用者様がポイントの付与を選択する場合、ポイントの利用、有効期間、払戻条件その他の条件は、当社が別途規定するブランディアポイント規約に定めるものとします。<br />
                2. 前項にかかわらず、お申込時に「申込時に査定結果の確認不要で現金で同意」（以下、「事前同意」）をご選択された場合については、弊社への申込完了をもって、前項の同意と支払方法選択（現金でのお振り込みに決定）を完了したものとします。<br />
                3. 支払方法選択後は、当社は、ご利用者様からの買取金額の支払方法の変更等のご要望その他一切の要求につき異議を受け付けないものとします。<br />
                4. 1項ないし2項に関して、お申し込み時に「申込時に査定結果の確認不要で現金で同意」（以下、事前同意）をご選択の場合についてのみは、弊社への申込完了をもって同意と支払方法の選択を確認できたものとします。<br />
                5. 古物営業法に従い、 原則として、ご入金口座は本人確認書類記載のご利用者様ご本人名義の口座に限ります。ただし、口座情報に不備がある場合、古物営業法に違反する場合、または、依頼先金融機関において問題が生じた場合は上記の限りではありません。<br />
                6. 査定金額が1万円以上の取引で、ポイントでの受け取りを希望される場合は、古物営業法に従い、ご利用者様ご本人名義の口座に10円をお振込みさせていただきます。なお、その際に発生した10円および振込手数料は当社にて負担させていだだきます。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第１３条： （振込手数料について）</h5>
              <p>
                買取金額の振込手数料は当社で負担させていただきます。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第１４条： （査定結果連絡）</h5>
              <p>
                1. 査定結果連絡はメールアドレス（携帯電話およびSMS等のその他のメッセージサービスを含む）を保有されている方はメールにてご連絡致します。メールを保有されない場合には、ＦＡＸでご連絡させていただきます。上記いずれにも該当しない場合には郵送にてご連絡させていただきます。<br />
                2. 当社が正しい方法にて連絡を取らせていただいた時点で連絡をさせていただいたものとみなします。<br />
                （ドメイン指定受信の解除がされておらず受信できなかったなどの理由はご利用者様の責任となりますのでご注意ください。）<br />
                3. 査定結果を連絡したにも関わらず、売却の可否についてその後3ヵ月間ご返答をいただくことができない場合には、当社はお預かりしたお品物をご利用者様に返却するものと致します。<br />
                4. 当社が返却したお品物をご利用者様が正当な理由なく受領しない場合（ご利用者様による受領拒否の場合のほか、ご利用者様の不在その他の理由によってご利用者様がお品物を受領せず、当社にお品物が返送された場合等を含みますが、これらに限りません。）、当社は、ご利用者様に対し郵便またはメールによって通知し、相当の期間を定めたうえで該当のお品物の受け取りにつきご利用者様に対して指図を求めるものと致します。ご利用者様から指図がある場合には、当社はその指図に従いお品物を再配送するものと致します。この再配送にかかる費用はご利用者様の負担と致します。また、該当のお品物については、現状有姿で引き渡すものであり、該当のお品物の状態（お品物の変質、変形、消耗、毀損または腐敗等を含みますが、これらに限りません。）について、当社は一切責任を負わないものとします。<br />
                5. 前項の通知に関わらず、相当の期間内にご利用者様からの指図がない場合、当社は、前項の通知の日から最大6ヶ月間、該当のお品物を保管するものと致します。この間、当社は、原則として、前項の通知の日から3ヶ月および6ヶ月後に、お品物受け取りを促す通知を郵便またはメールによって行うものと致します。<br />
                6. 当社は、前項の保管期間経過後、該当のお品物の保管期間が終了した旨、および、７日以内に受領についての連絡がない場合には、廃棄その他当社の定める処分方法により該当のお品物を処分する旨を、ご利用者様に対して郵便またはメールで通知するものとします。この通知から7日以内にご利用者様からご連絡がない場合には、当社はこの通知に従い、該当のお品物を処分することができるものとし、以後当社はご利用者様に対して一切の責任を負わないものと致します。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第１５条： （お品物の返却）</h5>
              <p>
                1. 当社が買取明細にて買取金額を明記させていただいたお品物に関して、ご利用者様の希望があれば部分的に返却させていただきます。ただし、第12条に定める査定金額の同意前である場合に限ります。<br />
                2. 買取金額がつかなかったお品物に関しては買取金額がつかなかったお品物全体の返却か、当社にて処分のいずれかをご選択いただけます。ただし、事前同意の場合は買取金額がつかなかったお品物全体を返却とし、選択はできません。また、当社処分は内容によってはお断りさせていただく場合もございますので、あらかじめご了承ください。<br />
                3. 前項にかかわらず、お申し込み時に「事前同意」をご選択された場合は、買取金額がつかなかったお品物全体を返却とし、ご選択はできません。<br />
                4. 当社にて複数のお品物をおまとめしてお値段をお付けした場合、対象のお品物全てを含めたお値段となります。そのため、対象のお品物の部分的なお品物の買取、返却、廃棄には応じかねます。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第１６条： （買取契約の成立および所有権の移転について）</h5>
              <p>
                1. 当社はお品物を査定した際、買取金額を提示致します。同意いただける場合は「買取」、そうでない場合は「返却」の旨をお伝えください。「買取」の場合、売買契約（以下「買取契約」）が成立し、本規約12条に基づく買取代金の支払完了時点またはポイント付与時点をもって、お品物の所有権は、ご利用者様から当社に移転いたします。ただし、お申し込み時に「事前同意」をご選択されたの場合についてのみは、買取金額がついた時点で、 弊社への申込完了をもって売買取契約が成立したものといたします。<br />
                2. 買取対象商品であっても、当社買取基準により買取金額がつかない場合の処理方法について、原則、お品物は返却致しますが、ご利用者様が査定結果に同意された上で、当社による廃棄を希望され当社が了承した場合は、その時点でお品物の所有権はご利用者様から当社に移転し、廃棄その他当社の定める処分・リサイクルを行います。<br />
                3. 不正品の疑いがあると判断された場合には、買取契約の成立前、後にかかわらず所轄警察署にその旨を申告するとともに法的対応をとらせていただく場合がありますので、あらかじめご了承ください。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第１７条： （キャンセルについて）</h5>
              <p>
                前条第1項の規定によって買取契約が成立した後のキャンセル、内容変更はできません。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第１８条： （当社の責任範囲）</h5>
              <p>
                1. 当社起因でお品物を破損、紛失した場合には客観的かつ合理的根拠に基づいた判断の上、補償をさせていただきます。なお、当社に故意または重過失がある場合を除き、該当商品の中古取引相場における価格を上限として損害賠償の責を負うものとします。<br />
                2. 査定のお品物送付中の紛失等の補償については、ご利用者様がお品物を購入された際のレシート等もしくは保証書の提示、また着用画像等の提示をお願いする場合がございます。<br />
                3. お品物の価値に直接かかわらないと当社が判断する、付属品（袋、レシート、箱、クリーニング袋、ハンガー等）に関する補償は致しません。<br />
                4. 故障や、時間、移動に伴う自然劣化は補償対象外とします。<br />
                5. 当社は、本サイトを利用する際に、コンピュータウイルスなど有害なプログラム等による損害を受けないことを保証しないものとします。<br />
                6. 当社は、本サイトを利用する際に使用するいかなる機器、ソフトウェアについても、その動作保証を一切しないものとします。<br />
                7. 当社はユーザーが本サイトを利用する際に発生する通信費用について、一切負担しないものとします。<br />
                8. 本規約に別途定める場合のほか、本条は、ご利用者様に対する当社の責任の全てを規定したものであり、当社は、当社に故意または重過失がある場合を除き、ご利用者様の逸失利益、間接損害、特別損害、 弁護士費用その他の本条に規定のない損害を賠償しないものとします。<br />
                9. お品物のポケットの中などに入っているご利用者様の私物（現金、カード類、鍵、ハンカチ等）に関する補償は致しません。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第１９条： （禁止行為）</h5>
              <p>
                1. ご利用者様は、以下の行為を行ってはならないものとします。<br />
                (1) ご利用の際に虚偽の内容を入力または登録申請する行為<br />
                (2) 本サイトを不正の目的をもって利用する行為<br />
                (3) 第三者になりすまして本サイトを利用する行為<br />
                (4) コンピュータウイルスなど有害なプログラム等を送信もしくは提供する行為、または推奨する行為<br />
                (5) 当社または第三者の情報を改ざん、消去する行為<br />
                (6) 当社または第三者の設備を不正に利用し、またはその運営に支障を与える行為<br />
                (7) 本サイトまたは本サービスの運営を妨げ、その他本サイトまたは本サービスに支障をきたすおそれのある行為<br />
                (8) クレジットカードあるいは携帯電話決済等を不正使用して本サービスを利用する行為<br />
                (9) 他のご利用者様、第三者もしくは当社に迷惑、不利益もしくは損害を与える行為、名誉若しくは信用を毀損する行為、プライバシーを侵害する行為またはそれらのおそれのある行為<br />
                (10) 他のご利用者様、第三者もしくは当社の著作権等の知的財産、プライバシー・人格権、パブリシティ権その他権利を侵害する行為、またはそれらのおそれのある行為<br />
                (11) 公序良俗に反する行為、詐欺に結び付く行為その他法令に違反する行為、またはそれらのおそれのある行為<br />
                (12) 故意に複数回に分けてお品物をお送りいただくこと、また明らかに査定・買取を目的としない行為<br />
                (13) その他、当社が不適当と判断する行為<br />
                2. ご利用者様の本サービスの利用に関連して当社が何らかの損害を被った場合、当社はご利用者様に対して損害の賠償を請求できるものとします。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第２０条： （本規約違反等への対処）</h5>
              <p>
                1. 当社は、ご利用者様が本規約に違反した場合、ご利用者様による本サービスの利用に関し他者からクレーム・請求等が為され、かつ当社が必要と認めた場合、またはその他の理由で本サービスの運営上不適当と当社が判断した場合は、当該ご利用者様に対し、次のいずれかまたはこれらを組み合わせた措置を講ずることがあります。<br />
                (1) 他者との間で、クレーム・請求等の解消のための協議を行うことを要求します。<br />
                (2) 事前に通知を要することなく、本サービスの利用停止処分とします。<br />
                2. ご利用者様は、前項の規定によって、当社が、同項に定める措置を講ずべき義務を負うものではないことを承諾します。また、ご利用者様は、当社が前項各号に定める措置を講じた場合に、当該措置に起因する結果に関し、当社に対し、いかなる異議苦情も申し述べないものとします。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第２１条： （個人情報の取り扱いについて）</h5>
              <p>
                1. ご利用者様は、当社にご提供いただいたご利用者様の個人情報が当社の個人情報保護方針（以下「個人情報保護方針」）に従って取り扱われることに同意するものとします。<br />
                2. 前項の規定にかかわらず、お品物等（保証書、ギャランティーカード等、電子機器の場合は電話帳のメモリ、写真、音楽、アプリ、その他データ）に記載されている個人情報については、個人情報保護方針の対象外とします。<br />
                3. 当社は個人情報保護方針に従い、第三者に対して、あらかじめご利用者様の同意を得て、当該同意の範囲内で、会員ＩＤ、氏名、性別、メールアドレス、電話番号、郵便番号、住所、会社名部署名等、生年月日、購入履歴等保有情報を提供することができるものとします。<br />
                4. 当社はプライバシーポリシーに従い、個人情報をあらかじめユーザーの同意を得ないで第三者に提供しないものとします。ただし、次の各号記載の場合、氏名や住所など直接特定の個人を識別することができる情報を除外した上で、当社グループ会社等第三者に対して、必要な範囲で個人情報を提供させていただきます。<br />
                (1) サービス等の提供に必要な場合<br />
                (2) サービス等の品質向上のために必要な場合<br />
                (3) 新たなサービス等の検討のために必要な場合<br />
                (4) 調査・研究・分析のために研究機関に提供する場合<br />
                <a href="https://brandear.jp/ct/policy" target="_blank">個人情報保護方針</a>
              </p>
            </div>

            <div className={styles.item}>
              <h5>第２２条： （規約・サービスの変更または廃止等について）</h5>
              <p>
                本規約および本サービスの内容は、予告なしに変更または廃止される場合がございます。その場合、本サイトにてお知らせ致します。
                本規約または本サービスの内容が変更された場合、当該変更は変更後になされる一切の取引に適用されるものとします。
                ご利用の際には、本ページの最新情報をご確認いただきますようお願い致します。
                当該変更後にご利用者様が本サービスを利用された場合には、ご利用者様は本規約または本サービスの変更内容に同意したものと致します。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第２３条： （サービスの利用停止等）</h5>
              <p>
                1. 当社は、ご利用者様が次の各号に該当すると判断した場合には、事前に通知を実施した上で、当該ご利用者様によるサービスの利用停止、当該ご利用者様のユーザーIDおよびパスワードの変更、当該ご利用者様のユーザー資格の取消し、またはその他の必要と認める措置を行うことができるものとします。これによりご利用者様に何らかの損害が生じたとしても、当社は一切責任を負わないものとします。<br />
                (1) ご利用者様に法令や本規約、会員規約、その他の当社が定める規約に違反する行為があった場合<br />
                (2) ご利用者様にサービス利用に関して不正行為があった場合<br />
                (3) 一定回数以上のパスワードの入力ミスがあるなどご利用者様のセキュリティを確保するために必要な場合<br />
                (4) その他当社が適当と判断した場合<br />
                2. 前項のほか、ご利用者様が当社の定める一定の期間内に一定回数のログインを行わなかった場合は、当社は、事前に通知することなく当該ご利用者様のユーザーIDおよびパスワードの変更またはユーザー資格の取消しを行うことができるものとします。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第２４条： （解約について）</h5>
              <p>
                ご利用者様が本サービスの解約を希望する場合には、ご利用者様ご本人がユーザー登録情報の削除を行ってください。
                所定の解約手続の終了後に、解約となります。また会員規約に基づき、会員はアカウントおよびすべてのデファクトスタンダード会員サービスについて、一切利用できなくなります。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第２５条： （反社会的勢力の排除）</h5>
              <p>
                1. ご利用者様は、本規約に同意いただく時点で、次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。<br />
                (1) 暴力団<br />
                (2) 暴力団員<br />
                (3) 暴力団準構成員<br />
                (4) 暴力団関係団体<br />
                (5) 総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団<br />
                (6) 本項第1号から第5号の者と社会的または経済的関係がある者<br />
                (7) その他前各号に準ずる者<br />
                2. ご利用者様は、自らまたは第三者を利用して次の各号に該当する行為を行わないことを確約します。<br />
                (1) 暴力的な要求行為<br />
                (2) 法的な責任を超えた不当な要求行為<br />
                (3) 取引に関して、偽計を用いまたは威力を用いて相手方の信用を毀損し、または業務を妨害する行為<br />
                (4) その他前各号に準ずる行為<br />
                3. 当社は、ご利用者様が第1項各号のいずれかに該当し、もしくは前項各号のいずれかに該当する行為をし、または第1項の規定に基づく表明・確約に関して虚偽の申告をしたことが判明した場合は、ご利用者様との一切の契約を解除することができるものとします。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第２６条： （準拠法および管轄について）</h5>
              <p>
                本規約の準拠法は、日本法とし、本規約または本サービスに関する紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
              </p>
            </div>
          </div>

          <div>
            <h4 className="mt-5">ブランディアBell特約</h4>

            <div className={styles.item}>
              <h5>第１条： （総則）</h5>
              <p>
                1. 本特約は、Brandear（ブランディア）ご利用規約（以下「ブランディア利用規約」という）に付随するものであり、ご利用者様が、ブランディア買取サービスを利用する際、本特約に同意の上、オンライン買取（次条にて定める）を申し込んだ場合に適用されます。<br />
                2. 本特約の内容が、ブランディア利用規約の各条項と矛盾抵触する場合には、本特約の定めが優先して適用されるものとし、本特約に定めのない事項はブランディア利用規約によるものとします。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第２条： (定義)</h5>
              <p>
                1. 本特約における用語の定義は、次の各号に定めるものとします。<br />
                (1) 「オンライン買取」とは、ビデオ通話ツールを用いてオンラインでお品物の査定・買取を行う方法をいいます。<br />
                (2) 「ビデオ通話ツール」とは、映像と音声を使って査定のためのコミュニケーションを取ることのできるツールであって、当社が指定するものをいいます。<br />
                (3) 「査定予約ツール」とは、第三者が提供するコミュニケーションアプリであって、当社が指定するものをいいます。<br />
                (4) 「ブランディアBell」とは、当社が提供するオンライン買取サービスの呼称をいいます。（以下「Bell」という）<br />
                2. 本特約に定めのない用語については、ブランディア利用規約の用語と同一の意味を有するものとします。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第３条： （査定環境）</h5>
              <p>
                1. Bellの予約及びオンライン買取は、査定予約ツール及びビデオ通話ツールを用いて実施します。ご利用者様は、あらかじめ査定予約ツールを提供する第三者が定める規約等に同意の上、自身の費用と責任を以てインストール及び必要な設定を行うものとします。<br />
                2. オンライン買取の性質上、大量のデータ通信が発生するため、Wi-Fi環境など、通信速度が安定した環境下での利用を推奨します。速度制限などによる音声または映像の遅延、中断、乱れ等のトラブル及び損害について、当社は一切責任を負わないものとします。<br />
                3. オンライン買取にかかる通信料及びインターネット接続料等の料金は、ご利用者様の負担となります。<br />
                4. ご利用者様は、当社またはご利用者様の通信環境または使用する端末の性能、その他要因により、音声の遅延、画像の乱れ、ビデオ通話の切断などの不具合が起きる可能性があることを予め了承した上でBellを利用するものとします。当社は、当該不具合によりご利用者様に損害が発生した場合、一切の責任を負わないものとします。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第４条： （禁止事項）</h5>
              <p>
                1. ご利用者様は、以下各号に定める行為をしてはならないものとします。<br />
                (1) オンライン買取中の映像を第三者に公開し、または視聴可能な状態にする行為<br />
                (2) オンライン買取を希望する意思が無いにもかかわらずBellの予約を申し込む行為<br />
                (3) 当社の承諾なく、同時に複数のBellの予約枠を確保する行為<br />
                (4) 常識的な範囲を超えてBellの予約とキャンセルを繰り返す行為<br />
                (5) 第三者の為にBellの予約を申し込む行為<br />
                (6) 自身が確保したBellの予約枠を第三者に譲渡する行為<br />
                (7) 接客員に対し暴言、人格否定発言その他の言動によって不快感を与える行為<br />
                (8) オンライン買取の進行を故意に妨害する行為<br />
                (9) 酩酊状態など、接客員との意思疎通が困難な状態でオンライン買取に臨む行為<br />
                (10) その他Bellの運営に支障をきたす行為
              </p>
            </div>

            <div className={styles.item}>
              <h5>第５条： （予約の確定・変更・キャンセル）</h5>
              <p>
                1. 査定予約ツール上でご利用者様が希望日程を選択し、当社が予約完了の通知を発信した時点でBellの予約が確定するものとします。<br />
                2. 予約枠１件につき、査定が可能なお品物は原則6点までとし、ご利用者様は、オンライン買取を希望するお品物の点数に応じた範囲内でBellの予約を申し込むことが出来るものとします。<br />
                3. 予約確定後、日程の変更またはキャンセルを希望する場合、ご利用者様は、当該予約時間より前に査定予約ツールを通して当社に連絡するものとします。<br />
                4. 査定当日、予約時間から15分を過ぎてもご利用者様がビデオ通話に参加されない場合、当社は、当該予約がキャンセルされたものとみなします。なお、当社は当該キャンセルに伴い、ご利用者様に対し何ら通知義務を負わないものとし、ご利用者様は予めこれを了承するものとします。<br />
                5. 当社は、合理的な事由によりBellの提供が困難と判断した場合、ご利用者様に対し予め通知をした上で、確定した予約日程を変更またはキャンセルできるものとします。<br />
                6. 前項による予約の変更またはキャンセルにより、ご利用者様に発生した損害について、当社は一切責任を負わないものとします。<br />
                7. ご利用者様は、予約確定からオンライン買取開始までの期間中、連絡が可能な状態を保持するものとし、査定予約ツールのアカウント変更などにより、当社の連絡が受信できない状態が発生した場合、遅滞なくその旨を当社に連絡するものとします。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第６条： （買取契約の成立及び所有権の移転）</h5>
              <p>
                1. 当社は、オンライン買取中に査定が完了したお品物について、ご利用者様に対し買取金額を提示するものとし、ご利用者様は、お品物ごとに買取の同意または不成立の意思を表明するものとします。<br />
                2. 前項において、所定の方法によりご利用者様が買取に同意の意思を表明した時点で、当該お品物の買取契約が成立するものとします。<br />
                3. ご利用者様は、前項により買取契約が成立した場合、キャンセルまたは契約内容の変更はできないものとします。<br />
                4. ご利用者様は、当社の指定する方法により、原則買取契約成立の翌日から1週間以内にお品物を発送するものとし、当社は、当該お品物が到着後速やかに検品（ブランディア利用規約第7条に定める取引の前提、及び当社買取基準を満たすかどうかの確認を指す。以下同じ）を行うものとします。<br />
                5. 前項における検品にて、問題が無いと当社が判断した場合、お品物の引渡が完了するものとし、お品物の所有権及び危険負担がご利用者様から当社へ移転するものとします。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第７条： （本人確認）</h5>
              <p>
                1. 当社は、買取取引をする際、古物営業法に基づき、当社所定の方法によりご利用者様の本人確認を行うものとします。ご利用者様は、必要情報を当社に申告するとともに、次の各号に掲げる身分証明書のうち、いずれかを提示するものとします。<br />
                (1) 運転免許証<br />
                (2) 住民基本台帳カード<br />
                (3) 個人番号（マイナンバー）カード<br />
                (4) パスポート<br />
                2. ご利用者様は、前項各号に掲げる身分証明書のうち、有効期限の定めがあるものについては、当該有効期限内のものを提示しなければなりません。<br />
                3. ご利用者様は、次の各号に該当する場合、身分証明書と併せて次項に掲げる補完書類を提示するものとします。<br />
                (1) 身分証明書記載の住所が現住所でない場合<br />
                (2) 本条第１項第４号に基づき提示したパスポートの発行日が2020年2月4日以降である場合<br />
                4. 前項における補完書類とは、次の各号に掲げる書類を指すものとします。なお、これらは、現住所が記載されているものであって、発行日から3か月以内のものに限り、有効とします。<br />
                (1) 納税証明書<br />
                (2) 社会保険料領収書<br />
                (3) 公共料金領収書<br />
                (4) その他住民票などの官公庁発行書類
              </p>
            </div>

            <div className={styles.item}>
              <h5>第８条： （買取代金の支払）</h5>
              <p>
                1. 当社は、売買契約成立後、買取代金を原則即日お支払いします。<br />
                2. 前項の銀行振込にかかる振込手数料は当社負担とします。<br />
                3. 第１項の定めにかかわらず、以下各号に定めるすべての条件に該当する取引の場合、前条第５項に定めるお品物の引渡完了後速やかに支払い処理を実行するものとします。<br />
                (1) Bellのご利用が初回である場合<br />
                (2) 買取金額の合計が30万円を超える場合<br />
                4. 買取代金の入金口座は、原則として本人確認書類記載のご利用者様本人名義の口座に限るものとします。
              </p>
            </div>

            <div className={styles.item}>
              <h5>第９条： （買取契約の解除）</h5>
              <p>
                1. 当社は、以下各号に定める事由が発生した場合、事前の通知なく、買取契約の全部または一部を解除することが出来るものとします。<br />
                (1) ご利用者様が指定した期限までにお品物の発送を行わない場合（但し当社または配送業者の都合による場合を除きます）<br />
                (2) 第６条第４項における検品にてお品物に問題があると当社が判断した場合<br />
                2. ご利用者様は、前項の定めにより契約解除となった場合、受取済みの買取代金を、遅滞なく当社が指定する銀行口座に振り込むことにより返金するものとします。<br />
                3. 前項の返金にかかる振込手数料は、ご利用者様の負担とします。
              </p>
            </div>
          </div>
        </div>
      )}

      <hr className={`${styles.covenantHr} m-0`} />
      <div
          className={styles.aboutCovenant}
          onClick={() => setShowCovenantAsp((prev) => !prev)}
      >
        <p className="mb-0">個人関連情報の取り扱いについて</p>
        <span>{showCovenantAsp ? 'ー' : '＋'}</span>
      </div>
      {showCovenantAsp && (
        <div className="mt-3 mb-4">
          <div>
              識別子、クリック日時、成果発生日時など、当社が提携しているASPが取得した個人関連情報は、当社に提供された後、当社で、当社が保有している個人情報と突合させ、個人データとして取得し、アフィリエイト広告の成果承認のために利用します。
              ただし、当社は、ASPに対して、取得した個人データを戻すことはいたしません。
          </div>
        </div>
      )}
      <hr className={`${styles.covenantHr} m-0`} />

      <div className="d-flex align-items-center mt-3">
        <img
          className={styles.covenantCheckBox}
          src={checked ? checkedBox : checkBox}
          onClick={()=> setChecked(prev => !prev)}
        />
        <p className="mb-0">
          ブランディアBell特約に同意します。
        </p>
      </div>
      <div className="d-flex align-items-top mt-3">
        <img
            className={`${styles.covenantCheckBox} mt-1`}
            src={checkedAsp ? checkedBox : checkBox}
            onClick={()=> setCheckedAsp(prev => !prev)}
        />
        <p className="mb-0">
          個人関連情報の取り扱いについて同意します。
        </p>
      </div>
    </div>
  );
};

export default Component;
