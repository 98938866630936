import * as React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Image
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import CovenantCheck from '../../molecules/CovenantCheck/CovenantCheck'
import * as styles from './RegisterMobilePhonePageForRealtime.scss';
import { SetReservationDataContext } from '../../../providers/ReservationContextProvider';
import { sendVerificationCode } from '../RegisterMobilePhonePage/RegisterMobilePhonePage';
import stepInputMobilePhone from '../../../assets/images/steps/realtimeReservation1.png';

/**
 * いまスグ用(通常用と処理がやや異なるため分けている)
 */
const Component: React.FC = () => {
  const history = useHistory();
  const setReservationData = React.useContext(SetReservationDataContext)

  const [mobilePhone, setMobilePhone] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [covenantChecked, setCovenantChecked] = React.useState(false)
  const [covenantAspChecked, setCovenantAspChecked] = React.useState(false)

  const defaultValues = {
    mobilePhone: '',
  };

  const { register, handleSubmit, errors, setValue, formState } = useForm<
    typeof defaultValues
  >({
    mode: 'onBlur',
    defaultValues,
  });

  /**
   * 初期読み込み時に予約が現時点でできるかの検証を行う
   */
  React.useEffect(() => {
    ;(async () => {
      setIsLoading(true)

      try {
        const { data } = await axios.get<string>(
          `${process.env.API_BASE_URL}/api/online-purchase-schedules/realtime-purchase-schedule-id`,
        )

        if (!data) {
          alert('ただいま、いますぐ予約可能な予約枠が存在しません。\n時間を空けて再度お試しください。')
          history.goBack()
          return;
        }

        // Slackに準備通知
        await axios.post(
          `${process.env.API_BASE_URL}/api/slack-notification/prepare-realtime-appraise`,
          {
            scheduleId: data,
          },
        )

        setReservationData((prev) => ({...prev, isRealtime: true}))
        setIsLoading(false)
      } catch {
        alert('エラーが発生しました。再度ページをお開きいただくか、LINE上でお問い合わせください。')
      }
    })()
  }, [])

  React.useEffect(() => {
    register(
      { name: 'mobilePhone' },
      {
        required: '必須です',
        pattern: {
          value: /[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{4}[-\s\.]?[0-9]{4,6}/,
          message: '電話番号の形式が間違っています',
        },
      }
    );
  }, [register])

  const onMobilePhoneChange = (e: any) => {
    const { value } = e.target;
    const x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,4})(\d{0,4})/);
    const newValue = x[1] + (x[2] ? '-' + x[2] : '') + (x[3] ? '-' + x[3] : '');
    setValue('mobilePhone', newValue, true);
    setMobilePhone(newValue);
  };

  const onSubmit = async ({ mobilePhone }: typeof defaultValues): Promise<void> => {
    // isLoading === trueの時、既にAPIを叩いているので、その場合はそのままreturn
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await sendVerificationCode(mobilePhone);
      setIsLoading(false)
      setReservationData((prev) => ({ ...prev, mobilePhone }))

      history.push('/realtime-request/sms-verify');
    } catch (err) {
      setIsLoading(false);
      // @ts-ignore
      const statuscode = err?.response?.status || 500
      if (statuscode > 399 && statuscode < 500) {
        return alert('電話番号に誤りがあります。正しく電話番号を入力してください。')
      }

      alert('エラーが発生しました。');
    }
  };

  return (
    <Container className={styles.webappContainer}>
      <Helmet>
        <title>SMS認証 | ブランディアBell</title>
      </Helmet>
      {isLoading && (
        <div className={styles.screenOverlay}>
          <Spinner animation="border" variant="info" />
        </div>
      )}

      <Row className={`justify-content-center`}>
        <Image className={styles.headerStep} src={stepInputMobilePhone} rounded />
      </Row>

      <Row className="p-3 pb-4 justify-content-md-center">
        <Col md={{ span: 7 }} lg={{ span: 10 }}>
          <h4 className={`mt-5 text-left ${styles.boldText}`}>
            電話番号をご登録ください。
          </h4>
          <p className="my-4">
            ショートメール(SMS)が受け取れる携帯電話番号の入力をお願いいたします。
          </p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="mobilePhone">
              <Form.Control
                className={`text-center ${styles.inputStyle}`}
                type="tel"
                name="mobilePhone"
                placeholder="090-0000-0000"
                value={mobilePhone}
                onChange={onMobilePhoneChange}
                isInvalid={formState.isSubmitted && !!errors.mobilePhone}
                isValid={formState.touched.mobilePhone && !errors.mobilePhone}
              />
              {formState.isSubmitted && errors.mobilePhone && (
                <Form.Text className="text-danger">
                  {errors.mobilePhone.message}
                </Form.Text>
              )}
            </Form.Group>

            <CovenantCheck
              checked={covenantChecked}
              setChecked={setCovenantChecked}
              checkedAsp={covenantAspChecked}
              setCheckedAsp={setCovenantAspChecked}
            />

            <Button
              type="submit"
              className={`mt-5 mb-2 pb-3 pt-3 btn-block ${styles.button}`}
              variant="light"
              disabled={mobilePhone === '' || !covenantChecked || !covenantAspChecked}
            >
              認証コードを送信する
            </Button>
          </Form>

          <div className="text-center my-4">
            <Button
              className={styles.backBtn}
              type="button"
              variant="link"
              onClick={history.goBack}
            >
              前へ戻る
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Component;
