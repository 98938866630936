import * as React from 'react';
import {
  Container,
  Row,
  Image,
} from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import * as styles from './CompleteRealtimeRequest.scss';
import stepConnectVideoUrl from '../../../assets/images/steps/realtimeReservation3.png';

const Component: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<{ videoCallUrl?: string }>();

  // GTMタグ発火のため一度リロードを挟む
  React.useEffect(() => {
    if (!state?.videoCallUrl) {
      alert('無効な操作です。お手数ですが、最初からやり直してください。')
      history.goBack()
    }

    const storageKey = 'isReloaded'
    if (localStorage.getItem(storageKey)) {
      localStorage.removeItem(storageKey)
      return;
    }

    localStorage.setItem(storageKey, '1')
    location.reload()
  }, []);

  return (
    <Container className={styles.webappContainer}>
      <Helmet>
        <title>いますぐ予約完了 | ブランディアBell</title>
      </Helmet>

      <Row className={`justify-content-center`}>
        <Image className={styles.headerStep} src={stepConnectVideoUrl} rounded />
      </Row>

      <div className={styles.description}>
        <h5 className={`mt-5 ${styles.boldText}`}>ただいま順番にお繋ぎしております。</h5>
        <p className="my-4">ご対応状況によってはお待ちいただく事もございます。<br />入室いただき、画面が変わるまでお待ちください。</p>
      </div>

      <div className="text-center my-4">
        <a
          className={`mt-4 mb-2 pb-3 pt-3 btn-block text-center text-decoration-none ${styles.button}`}
          href={state?.videoCallUrl}
        >
          接続を開始する
        </a>
      </div>
    </Container>
  );
};

export default Component;
