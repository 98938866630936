import * as React from 'react';

import {
  MAX_QTY_FOR_30_MINUTES_FRAME,
  MAX_QTY_FOR_60_MINUTES_FRAME,
  MAX_QTY_FOR_90_MINUTES_FRAME,
  MAX_QTY_FOR_120_MINUTES_FRAME
} from 'line-app/src/enums/SelectablePurchaseItemsQty';
import * as styles from './ReservationInfo.scss';

type Props = {
  startAt: Date,
  selectedQty: number,
  remarks: string,
}

const Component: React.FC<Props> = ({
  startAt,
  selectedQty,
  remarks,
}) => {

  /**
   * 例: 2022年9月16日（金）
   */
  const formatDate = (date: Date) => {
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日（${['日', '月', '火', '水', '木', '金', '土'][date.getDay()] }）`
  }

  /**
   * 数値を二桁の文字列に変換(ex: 9 -> 09)
   */
  const convertDoubleDigitStr = (n: number): string => {
    return n < 10 ? `0${n}` : String(n)
  }

  /**
   * 例: 12:00
   */
  const formatStartTime = (date: Date) => {
    return `${convertDoubleDigitStr(date.getHours())}:${convertDoubleDigitStr(date.getMinutes())}`
  }

  /**
   * 持込点数から終了時間を判断
   * 例: 12:00
   */
  const formatEndTime = (date: Date, selectedQty: number) => {

    const purchaseMinutes = getPurchaseMinutes(selectedQty);

    date.setMinutes(date.getMinutes() + purchaseMinutes)

    return `${convertDoubleDigitStr(date.getHours())}:${convertDoubleDigitStr(date.getMinutes())}`
  }

  /**
   * 持込点数から予約枠の時間を取得
   */
  const getPurchaseMinutes = (selectedQty: number) => {
    switch (selectedQty) {
      case MAX_QTY_FOR_30_MINUTES_FRAME:
        return 30;
      case MAX_QTY_FOR_60_MINUTES_FRAME:
        return 60;
      case MAX_QTY_FOR_90_MINUTES_FRAME:
        return 90;
      case MAX_QTY_FOR_120_MINUTES_FRAME:
        return 120;
      default:
        return 0;
    }
  }

  return (
    <section className="py-4 px-2">
      <div className="d-flex">
        <p className={styles.reservationLabel}>所要時間</p>
        <p className="mb-0">30 - 60分</p>
      </div>
      <div className="d-flex">
        <p className={styles.reservationLabel}>日時</p>
        <p className="mb-0">
          {formatDate(startAt)}<br />
          {formatStartTime(startAt)} - {formatEndTime(startAt, selectedQty)}
        </p>
      </div>
      <hr className="my-3" />
      <div>
        <p className={styles.reservationLabel}>お品物情報</p>
        <p className={styles.remarks}>{ remarks }</p>
      </div>
    </section>
  );
};

export default Component;
