import * as React from 'react';
import {
    Container,
    Row,
    Col,
    Button,
} from 'react-bootstrap';
import {Helmet} from 'react-helmet';

import {
    AccountInfo,
    JobEnum,
    PickupDateTime,
    PickupTimeFrameEnum,
    ReferralFriend,
    UserInfo,
    BusinessInvoice,
    PickupAddress,
} from '../../../types/types';
import * as styles from "../ConfirmPurchaseDetail/ConfirmPurchaseDetail.scss";
import checkedBox from "../../../assets/images/checkedBox.png";
import checkBox from "../../../assets/images/checkBox.png";
import {PurchaseDetailNameSpace} from "../../../types/enums";


type Props = {
    userInfo: UserInfo,
    accountInfo: AccountInfo,
    pickupDateTime: PickupDateTime,
    referralFriend: ReferralFriend,
    pickupAddress: string
    isPermittedMailMagazine: boolean,
    purchaseFormId: string
    setShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>,
    setSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>,
    handleSubmit: () => Promise<void>,
    formTypeId: string,
    jobEnum: JobEnum | any,
    pickupTimeFrameEnum: PickupTimeFrameEnum | any,
    pickupAddressChecked: boolean,
    pickupAddressInfo: PickupAddress,
    businessInvoice: BusinessInvoice,
}


const Component: React.FC<Props> = ({
    userInfo,
    accountInfo,
    pickupDateTime,
    referralFriend,
    isPermittedMailMagazine,
    setShowConfirmation,
    setSubmitDisabled,
    handleSubmit,
    formTypeId,
    jobEnum,
    pickupTimeFrameEnum,
    pickupAddressChecked,
    pickupAddressInfo,
    businessInvoice
}) => {
    return (
        <Container>
            <Helmet>
                <title>以下の内容で送信してよろしいですか？</title>
            </Helmet>
            <h3 className={styles.customTitle}>
                <div className={styles.customTitle1}>以下の内容で送信して</div>
                <div className={styles.customTitle1}>よろしいですか？</div>
            </h3>
            <h6 className={`mt-4 pb-4 ${styles.customTitle}`}>
                {formTypeId == "2" ? (<>送信完了で集荷手続きを致します。</>) : (<>送信完了で買取代金のお振込を致します</>)}
            </h6>

            <h5 className={styles.customSubTitle}>
                ご本人様情報
            </h5>
            {formTypeId !== "3" && (
                <>
                    <Row className="mt-4">
                        <Col sm="5" xs="12" className="mb-2">
                            <strong className="d-none d-sm-block">
                                お名前<br/>(本人確認書類と同一表記)
                            </strong>
                            <strong className="d-sm-none">
                                お名前 (本人確認書類と同一表記)
                            </strong>
                        </Col>
                        <Col sm="3" xs="6" className={styles.customSubTitleContent}>
                            <strong>
                            姓
                            </strong>
                            <div>
                                {userInfo.userSurname}
                            </div>
                        </Col>
                        <Col sm="3" xs="6">
                            <strong>
                                名
                            </strong>
                            <div>
                                {userInfo.userName}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm="5" xs="12" className="mb-2">
                            <strong className="d-none d-sm-block">
                                お名前<br/>(全角フリガナ)
                            </strong>
                            <strong className="d-sm-none">
                                お名前 (全角フリガナ)
                            </strong>
                        </Col>
                        <Col sm="3" xs="6" className={styles.customSubTitleContent}>
                            <strong>
                                セイ
                            </strong>
                            <div>
                                {userInfo.userSurnameKana}
                            </div>
                        </Col>
                        <Col sm="3" xs="6">
                            <strong>
                                メイ
                            </strong>
                            <div>
                                {userInfo.userNameKana}
                            </div>
                        </Col>
                    </Row>
                </>
            )}
            {formTypeId !== "3" && (
                    <Row className="mt-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                ご住所
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div className="mb-2">{userInfo.postcode}</div>
                            <div className="mb-2">{userInfo.address}</div>
                            <div className="mb-2">{userInfo.address1}</div>
                            <div>{userInfo.address2}</div>
                        </Col>
                    </Row>
            )}
            {formTypeId !== "2" && formTypeId !== "5" && formTypeId !== "7" && (
                <>
                    <Row className="mt-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                生年月日
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div>
                                {userInfo.birthday}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                メールアドレス
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div>
                                {userInfo.email}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4 mb-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                ご職業
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div>{jobEnum?.[userInfo.job]}</div>
                        </Col>
                    </Row>
                    {userInfo.job === PurchaseDetailNameSpace.JobEnum.OTHER && (
                        <Row className="mt-4 mb-4">
                            <Col sm="5" className="mb-2">
                                <strong>
                                    他の職業
                                </strong>
                            </Col>
                            <Col sm="7" className={styles.customSubTitleContent}>
                                <div>{userInfo.detailJobOther}</div>
                            </Col>
                        </Row>
                    )}
                </>
            )}

            <hr className={styles.customHr}/>
            {formTypeId !== "2" && formTypeId !== "5" && formTypeId !== "7" && (
                <>
                    <h5 className={`d-none d-sm-block ${styles.customSubTitle}`}>
                        お口座情報（本人確認書類と同一表記）
                    </h5>
                    <h5 className={`d-sm-none ${styles.customSubTitle}`}>
                        <div>
                            お口座情報
                        </div>
                        <div>
                            (本人確認書類と同一表記）
                        </div>
                    </h5>
                    <Row className="mt-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                金融機関名
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div>
                                {accountInfo.bankName}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                支店番号
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div>
                                {accountInfo.branchNumber}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                支店名
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div>
                                {accountInfo.branchName}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                口座種別
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div>{accountInfo.accountType}</div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                口座番号
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div>{accountInfo.accountNumber}</div>
                        </Col>
                    </Row>
                    <Row className="mt-4 mb-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                口座名義（半角フリガナ）
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div>{accountInfo.accountName}</div>
                        </Col>
                    </Row>
                    <hr className={styles.customHr}/>
                </>
            )}
            {formTypeId !== "3" && formTypeId !== "6" && formTypeId !== "7" && (
                <>
                    <h5 className={styles.customSubTitle}>
                        集荷ご希望日時
                    </h5>
                    <Row className="mt-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                集荷日
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div>
                                {pickupDateTime.pickupDate}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4 mb-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                集荷時間
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div>
                                {pickupTimeFrameEnum?.[pickupDateTime.pickupTimeFrame]}
                            </div>
                        </Col>
                    </Row>
                    <hr className={styles.customHr}/>
                </>
            )}
            {formTypeId == "2" && (
                <>
                    <Row className="mt-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                集荷先を別に指定
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div>
                                {pickupAddressChecked ? '有' : '無'}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                郵便番号
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div>
                                {pickupAddressInfo.pickupPostcode}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4 mb-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                集荷先ご住所
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div className="mb-2">{pickupAddressInfo.pickupAddress}</div>
                            <div className="mb-2">{pickupAddressInfo.pickupAddress1}</div>
                            <div>{pickupAddressInfo.pickupAddress2}</div>
                        </Col>
                    </Row>
                </>
            )}
            {formTypeId !== "2" && formTypeId !== "5" && formTypeId !== "7" && (
                <>
                    <h5 className={styles.customSubTitle}>
                        お友達からご紹介されたお客様
                    </h5>
                    <Row className="mt-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                ご紹介者様のご利用日
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div>
                                {referralFriend.usedDate}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4 mb-4">
                        <Col sm="5" className="mb-2">
                            <strong>
                                ご紹介者様のお名前
                            </strong>
                        </Col>
                        <Col sm="7" className={styles.customSubTitleContent}>
                            <div>
                                {referralFriend.friendName}
                            </div>
                        </Col>
                    </Row>
                    <hr className={styles.customHr}/>
                    <div className="mt-4 mb-4">
                        <img
                            className={styles.covenantCheckBox}
                            src={isPermittedMailMagazine ? checkedBox : checkBox}
                        />
                        <span>メールでお得な情報を受取る</span>
                    </div>
                    <hr className={styles.customHr}/>
                </>
            )}
            <Row className="mt-4">
                <Col sm="5" className="mb-2">
                    <strong>
                        請求書の有無
                    </strong>
                </Col>
                <Col sm="7" className={styles.customSubTitleContent}>
                    <div>
                        {businessInvoice.isBusinessInvoice ? 'はい' : 'いいえ'}
                    </div>
                </Col>
            </Row>
            <Row className="mt-4 mb-4">
                <Col sm="5" className="mb-2">
                    <strong>
                        請求書番号
                    </strong>
                </Col>
                <Col sm="7" className={styles.customSubTitleContent}>
                    <div>
                        {businessInvoice.businessInvoiceRegistrationNumber}
                    </div>
                </Col>
            </Row>
            <Row className="mt-4 mb-4 d-flex">
                <Col sm="6" xs="12">
                    <div
                        className={`text-center ${styles.backForm}`}
                        onClick={() => {
                            setShowConfirmation(false);
                            setSubmitDisabled(false);
                        }}
                    >
                        入力画面に戻る
                    </div>
                </Col>
                <Col sm="6" xs="12" className={`${styles.firstOrder}`}>
                    <Button
                        type="button"
                        className={`pb-3 pt-3 btn-block ${styles.firstOrder} ${styles.confirmBtn}`}
                        variant="light"
                        onClick={handleSubmit}
                    >
                        送信する
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default Component;
