import * as React from 'react';
import { Container, Row, Table, Image, Spinner, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';


import { CertainPeriodPurchaseSchedules } from 'line-app/src/types/types';

import * as styles from './SelectPurchaseSchedulePage.scss';

import stepPurchaseSchedule from '../../../assets/images/steps/reservation1.png';
import changeStepPurchaseSchedule from '../../../assets/images/steps/chengeReservation1.png';
import { ReservationDataContext, SetReservationDataContext } from '../../../providers/ReservationContextProvider';

const Component: React.FC = () => {
  const history = useHistory();
  const { token, isChangeReservation } = React.useContext(ReservationDataContext)
  const setReservationData = React.useContext(SetReservationDataContext)

  const [purchaseSchedules, setPurchaseSchedules] = React.useState<CertainPeriodPurchaseSchedules>();
  const [yearMonthColQty, setYearMonthColQty] = React.useState<{[YearMonthString: string]: number }>();
  const [isLoading, setIsLoading] = React.useState(true);

  //買取スケジュール(maxdayforcalendar日分)を取得
  const getPurchaseSchedules = () => (
    axios.get<CertainPeriodPurchaseSchedules>
      (
        `${process.env.API_BASE_URL}/api/online-purchase-schedules/certain-period-schedules`
      )
  )


  /**
   * 予約可能枠数によってアイコンを切り替え表示
   * @param bookableCount
   */
  const displayIconByBookableCount = (bookableCount: number): JSX.Element => {
    switch (true) {
      case bookableCount === 0:
        return <span>×</span>
      case bookableCount === 1:
        return <span className={`${styles.thinIcon} ${styles.triangle}`}>△</span>
      case bookableCount >= 2:
        return <span className={styles.thinIcon}>○</span>
      default:
        return <span>×</span>
    }
  }

  const onScheduleClick = (scheduleId: number) => {
    setReservationData((prev) => ({ ...prev, scheduleId }))

    // トークン発行済みであればSMS認証はスキップ
    token
      ? history.push('/purchase-request/items')
      : history.push('/purchase-request/sms-auth')
  }

  React.useEffect(() => {
    ;(async () => {
      try {

        const purchaseSchedulesData = getPurchaseSchedules();
        setPurchaseSchedules((await purchaseSchedulesData).data)
      } catch {
        alert('エラーが発生しました。')
      }

      setIsLoading(false)
    })()
  }, []);

  // カレンダーの年月更新時、カラムが分かれるようにする
  React.useEffect(() => {
    if (!purchaseSchedules) {
      return
    }

    const dateStrings = Object.keys(purchaseSchedules)
    const convertDateObj = (dateString: string): Date => new Date(dateString.slice(0, -3)) // 末尾3文字の曜日「（水）」を削除することでDateオブジェクトとして扱える
    const formatYearMonth = (dateString: string): string => `${convertDateObj(dateString).getFullYear()}年${convertDateObj(dateString).getMonth() + 1}月` // ex) 2022年3月
    const formatMonth = (dateString: string): string => `${convertDateObj(dateString).getMonth() + 1}月` // ex) 3月 （getMonthでは0~11の範囲なので+1）
    const formatDay = (dateString: string): number => convertDateObj(dateString).getDate() // ex) 1

    let yearMonthStr = formatYearMonth(dateStrings[0])
    const originYearMonthStr = formatYearMonth(dateStrings[0])

    // {年月:colspanの値}となるオブジェクトを定義
    let adjustYearMonthColQty = { [yearMonthStr]: dateStrings.length }
    dateStrings.forEach((dateString, i) => {
      const targetYearMonth = formatYearMonth(dateString)

      // 年月が変化するとき、翌月となる値を該当オブジェクトに追加する
      if (yearMonthStr !== targetYearMonth) {
        adjustYearMonthColQty[yearMonthStr] = i
        adjustYearMonthColQty[targetYearMonth] = dateStrings.length - i
        yearMonthStr = targetYearMonth
      }
    })
    // APIから取得した最後のスケジュールが月初日(1日)、または最初のスケジュールが月末日(28~31日)の場合、月のみを表示する
    // 元の'{年月:colspanの値}'プロパティを削除後、{月:colspanの値}'の新たなプロパティを追加
    let onlyMonth : string;
    switch(true) {
      case formatDay(dateStrings.slice(-1)[0]) === 1 :
        delete adjustYearMonthColQty[yearMonthStr]
        onlyMonth = formatMonth(dateStrings.slice(-1)[0])
        adjustYearMonthColQty[onlyMonth] = adjustYearMonthColQty[yearMonthStr]
        break;
      case [28, 29, 30, 31].includes(formatDay(dateStrings[0])) && formatDay(dateStrings[1]) === 1:
        delete adjustYearMonthColQty[originYearMonthStr]
        onlyMonth = formatMonth(dateStrings[0])
        let onlyMonthAndColQty = { [onlyMonth] : adjustYearMonthColQty[originYearMonthStr] }
        adjustYearMonthColQty = Object.assign(onlyMonthAndColQty, adjustYearMonthColQty);
        break;
    }

    setYearMonthColQty(adjustYearMonthColQty)
  },[purchaseSchedules])


  return (
    <Container className={styles.webappContainer}>
      <Helmet>
        <title>日時選択 | ブランディアBell</title>
      </Helmet>
      <Row className={`justify-content-center`}>
        {isChangeReservation
            ? <Image className={styles.headerStep} src={changeStepPurchaseSchedule} rounded/>
            : <Image className={styles.headerStep} src={stepPurchaseSchedule} rounded/>
        }
      </Row>
      {isLoading && (
        <div className={styles.screenOverlay}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      <div className={`mb-2 mt-5`}>
        <div>

            <h5 className={`${styles.boldText}`}>
              ご希望の日時<sup className={`${styles.textSoftRed} ${styles.supText}`}>※必須</sup>
            </h5>

          <div className={`${styles.supplementaryExplanation}`}>
            <p className="mb-0">
              ※商品点数の目安は1～6点です。
            </p>
            <p className="mb-0">
              ※延長や査定商品の追加のご希望は接客員にご相談ください。
            </p>
          </div>
          <div className="mt-2">
            <p className={`${styles.middleText}`}>30 - 60分のご予約をお取りします。</p>
          </div>
        </div>
      </div>

      {purchaseSchedules && yearMonthColQty && (
        <>
          <div className={styles.scrollTable}>
            <Table>
              <thead className="sticky-top bg-white">
              <tr>
                <th rowSpan={2} className={styles.blankSpace}></th>
                {
                  Object.keys(yearMonthColQty).map((yearMonthStr, i) => {
                    const colQty = yearMonthColQty[yearMonthStr]
                    return (
                        <th colSpan={colQty} className={styles.scheduleMonthText} key={i}>
                          {yearMonthStr}
                        </th>
                    )
                  })
                }
              </tr>
              <tr>
                {/* maxdayforcalendar日分の日付を初期表示 */}
                {Object.keys(purchaseSchedules).map((dateString, index) => {
                  const isSaturday = dateString.indexOf('土') !== -1
                  const isSunday = dateString.indexOf('日') !== -1

                  return (
                      <th
                          key={index}
                          className={` ${styles.scheduleDateTextContainer} text-center`}
                          style={{
                            color:
                                isSaturday
                                    ? '#0081C0' // 青
                                    : isSunday
                                        ? '#E35450' // 赤
                                        : ''
                          }}
                      >
                        {/* 日付と曜日の間に改行を入れる */}
                        { dateString.replace('(', '\n').split('\n').map((dayStr: string, index) => {
                              // 日付と曜日のみ表示
                              const day = dayStr.slice(-2).replace('/', '').replace(')', '');
                              return <React.Fragment key={index}>{day}<br /></React.Fragment>
                            }
                        )}
                      </th>
                  )
                })
                }
              </tr>
              </thead>
              <tbody>
                <tr>
                  {/* 時間表示（オブジェクトの最初の要素から時間のみ抽出） */}
                  <th className={styles.scheduleTimeTextContainer}>
                    {purchaseSchedules[Object.keys(purchaseSchedules)[0]].map(({ time }) =>
                      (
                        <p key={time} className={`${styles.scheduleTimeText}`}>
                          {time}
                        </p>
                      )
                    )}
                  </th>

                  {Object.values(purchaseSchedules).map((schedules, i) =>
                    <th className={styles.scheduleDateTitle} key={i}>
                      {schedules.map(({ onlinePurchaseScheduleId, bookableCount }, index) =>
                        bookableCount > 0 && onlinePurchaseScheduleId !== 0
                          ?
                            <button
                              key={index}
                              type="button"
                              className={styles.itemButton}
                              onClick={() => onScheduleClick(onlinePurchaseScheduleId)}
                            >
                              {displayIconByBookableCount(bookableCount)}
                            </button>
                          :
                            <p className={`${styles.itemButton} ${styles.notBookable}`} key={index}>
                              {displayIconByBookableCount(0)}
                            </p>
                      )}
                    </th>
                  )}
                </tr>
              </tbody>
            </Table>
          </div>

          <div className={`${styles.usageGuide} ml-1 mt-1`}>
            <p className="mr-2">○空きあり</p>
            <p className="mr-2">△残り1枠</p>
            <p>
              ×満室のため
              <a
                className={styles.lineLink}
                href={`https://line.me/R/ti/p/${process.env.BOT_BASIC_ID}`}
                target="_blank"
              >LINE
              </a>
              にてお問い合わせください。
            </p>
          </div>

          <div className="text-center my-4">
            <Button
              className={styles.backBtn}
              type="button"
              variant="link"
              onClick={history.goBack}
            >
              戻る
            </Button>
          </div>
        </>
      )}
  </Container>
  );
};

export default Component;
