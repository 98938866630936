import * as React from 'react';
import {Form, Button} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import * as styles from './InputPurchaseItemForm.scss';
import { ReservationDataContext, SetReservationDataContext } from '../../../providers/ReservationContextProvider';

const Component: React.FC = () => {
  const history = useHistory();
  const { token, scheduleId, selectedQty, remarks } = React.useContext(ReservationDataContext)
  const setReservationData = React.useContext(SetReservationDataContext)
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    return history.push('/purchase-request/confirm')
  }

  React.useEffect(() => {
    if (!token || !scheduleId || selectedQty === 0) {
      alert('SMS未認証、もしくは予約に必要な情報が不足しています。\nお手数ですが最初からやり直してください。')
      return history.push('/purchase-request/schedules')
    }
  }, []);

  return (
    <Form className="flex-fill" onSubmit={handleSubmit} noValidate>
      <Form.Group controlId="h" className="mb-1">
        <h5 className={`${styles.boldText} mb-2`}>
            お品物情報<sup className={`${styles.supText} ${styles.textPlaceholder} pl-1`}>※任意</sup>
        </h5>
        <div className={`${styles.supplementaryExplanation}`}>
          <p className="mb-0">
          ※ご記入頂くことでよりスムーズな査定が可能です。
          </p>
        </div>
        <Form.Control
            as="textarea"
            rows={3}
            placeholder="ルイヴイトン・バッグ
エルメス・財布"
            className={`${styles.formText}`}
            defaultValue={remarks}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              e.persist()
              setReservationData((prev) => ({...prev, remarks: e.target.value}));
            }}
        />
      </Form.Group>

      <Button
          type="submit"
          className={`mt-5 mb-2 pb-3 pt-3 btn-block ${styles.button}`}
          variant="light"
      >
        予約内容の確認へ
      </Button>

      <div className="text-center my-4">
        <Button
            className={styles.backBtn}
            type="button"
            variant="link"
            onClick={history.goBack}
        >
          前へ戻る
        </Button>
      </div>
    </Form>
  );
};

export default Component;
