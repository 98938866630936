import * as React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Image
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import SelectedPurchaseItemEntry from '../../molecules/SelectedPurchaseItemEntry/SelectedPurchaseItemEntry';
import SelectedPurchaseRequestEntry from '../../molecules/SelectedPurchaseRequestEntry/SelectedPurchaseRequestEntry';

import * as styles from './RegisterMobilePhonePage.scss';
import { SetReservationDataContext } from '../../../providers/ReservationContextProvider';
import stepPurchaseSchedule from '../../../assets/images/steps/reservation2.png';

import CovenantCheck from '../../molecules/CovenantCheck/CovenantCheck'
export const sendVerificationCode = async (
  mobilePhone: string,
) => {
  const mobilePhoneWithoutHyphen =
    '+81' + mobilePhone.replace(/-/g, '').replace(/^0/, '');

  await axios.put(
    `${process.env.API_BASE_URL}/api/web-app/sms-auth/send`,
    { mobilePhone: mobilePhoneWithoutHyphen },
  );
};

/**
 * 通常査定用電話番号入力ページ
 */
const Component: React.FC = () => {
  const history = useHistory();
  const setReservationData = React.useContext(SetReservationDataContext)

  const [mobilePhone, setMobilePhone] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const [covenantChecked, setCovenantChecked] = React.useState(false)
  const [covenantAspChecked, setCovenantAspChecked] = React.useState(false)
  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const defaultValues = {
    mobilePhone: '',
  };

  const { register, handleSubmit, errors, setValue, formState } = useForm<
    typeof defaultValues
  >({
    mode: 'onBlur',
    defaultValues,
  });

  React.useEffect(() => {
    register(
      { name: 'mobilePhone' },
      {
        required: '必須です',
        pattern: {
          value: /[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{4}[-\s\.]?[0-9]{4,6}/,
          message: '電話番号の形式が間違っています',
        },
      }
    );
  }, [register]);
  // 『特約』『個人関連情報の取り扱い』共にチェック済みの時のみボタンを押下可能にする
  React.useEffect(() => {
    setSubmitDisabled(!covenantChecked || !covenantAspChecked)
  }, [covenantChecked, covenantAspChecked]);

  const onMobilePhoneChange = (e: any) => {
    const { value } = e.target;
    const x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,4})(\d{0,4})/);
    const newValue = x[1] + (x[2] ? '-' + x[2] : '') + (x[3] ? '-' + x[3] : '');
    setValue('mobilePhone', newValue, true);
    setMobilePhone(newValue);
  };

  const onSubmit = async ({ mobilePhone }: typeof defaultValues): Promise<void> => {
    // isLoading === trueの時、既にAPIを叩いているので、その場合はそのままreturn
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await sendVerificationCode(mobilePhone);
      setIsLoading(false)
      setReservationData((prev) => ({ ...prev, mobilePhone }))

      history.push('/purchase-request/sms-verify');
    } catch (err) {
      setIsLoading(false);
      // @ts-ignore
      const statuscode = err?.response?.status || 500
      if (statuscode > 399 && statuscode < 500) {
        return alert('電話番号に誤りがあります。正しく電話番号を入力してください。')
      }

      alert('エラーが発生しました。');
    }
  };

  return (
    <Container className={styles.webappContainer}>
      <Helmet>
        <title>SMS認証 | ブランディアBell</title>
      </Helmet>
      {isLoading && (
        <div className={styles.screenOverlay}>
          <Spinner animation="border" variant="info" />
        </div>
      )}

      <Row className={`justify-content-center`}>
        <Image className={styles.headerStep} src={stepPurchaseSchedule} rounded />
      </Row>

      <SelectedPurchaseItemEntry/>
      <SelectedPurchaseRequestEntry/>

      <Row className="p-3 pb-4 pt-5 justify-content-md-center">
        <Col md={{ span: 12 }} lg={{ span: 12 }}>
          <Row className="p-3 justify-content-md-center">
            <Col md={{span: 7}} lg={{span: 10}}>
              <h5 className={`${styles.boldText} mb-3`}>
                電話番号のご登録<sup className={`${styles.textSoftRed} ${styles.supText}`}>※必須</sup>
              </h5>
              <p className="my-4">
                ショートメール(SMS)が受け取れる携帯電話番号の入力をお願いいたします。
              </p>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="pl-3 pr-3 justify-content-md-center">
              <Col md={{span: 7}} lg={{span: 10}}>
                <Form.Group controlId="mobilePhone">
                  <Form.Control
                      className={`text-center ${styles.inputStyle}`}
                      type="tel"
                      name="mobilePhone"
                      placeholder="090-0000-0000"
                      value={mobilePhone}
                      onChange={onMobilePhoneChange}
                      isInvalid={formState.isSubmitted && !!errors.mobilePhone}
                      isValid={formState.touched.mobilePhone && !errors.mobilePhone}
                  />
                  {formState.isSubmitted && errors.mobilePhone && (
                      <Form.Text className="text-danger">
                        {errors.mobilePhone.message}
                      </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <CovenantCheck
                checked={covenantChecked}
                setChecked={setCovenantChecked}
                checkedAsp={covenantAspChecked}
                setCheckedAsp={setCovenantAspChecked}
            />
            <Button
              type="submit"
              className={`mt-5 mb-2 pb-3 pt-3 btn-block ${styles.button}`}
              variant="light"
              disabled={submitDisabled || mobilePhone === ''}
            >
              認証コードを送信する
            </Button>
          </Form>

          <div className="text-center my-4">
            <Button
              className={styles.backBtn}
              type="button"
              variant="link"
              onClick={history.goBack}
            >
              前へ戻る
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Component;
