import * as React from 'react';
import {
  Container,
  Form,
  Button,
  Spinner,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import * as styles from './ConfirmReservationInfoPage.scss';

import ReservationInfo from '../../molecules/ReservationInfo/ReservationInfo'
import { OnlinePurchaseSchedule } from '../../../types/onlinePurchaseSchedule';
import { ReservationDataContext } from '../../../providers/ReservationContextProvider';


const Component: React.FC = () => {
  const history = useHistory();

  const {
    remarks,
    selectedQty,
    scheduleId,
    mobilePhone,
    beforeRequestId,
    beforeAssessmentKpiId,
    token,
  } = React.useContext(ReservationDataContext)

  const [onlinePurchaseSchedule, setOnlinePurchaseSchedule] = React.useState<OnlinePurchaseSchedule>()
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    ;(async () => {
      if (!token || !scheduleId || selectedQty === 0) {
        alert('SMS未認証、もしくは予約に必要な情報が不足しています。\nお手数ですが最初からやり直してください。')
        return history.push('/purchase-request/schedules')
      }

      try {
        const { data } = await axios.get<OnlinePurchaseSchedule>(
          `${process.env.API_BASE_URL}/api/web-app/online-purchase-schedules/${scheduleId}`
        )
        setOnlinePurchaseSchedule(data)
        setIsLoading(false);
      } catch (e) {
        alert('エラーが発生しました。選択した日時が予約可能かご確認ください')
        return history.push('/purchase-request/schedules')
      }
    })()
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    setIsLoading(true);

    try {
      const { data } = await axios.post<{
        purchaseRequestId: string,
        assessmentKpiId: string,
        hashedScheduleId: string,
      }>(
        `${process.env.API_BASE_URL}/api/web-app/purchase-requests/complete`,
        {
          mobilePhone: '+81' + mobilePhone.replace(/-/g, '').replace(/^0/, ''),
          scheduleId,
          selectedQty,
          remarks,
          beforeRequestId,
          beforeAssessmentKpiId,
          token,
        },
      )

      setIsLoading(false);

      history.push('/purchase-request/complete', {
        onlinePurchaseSchedule,
        purchaseRequestId: data.purchaseRequestId,
        hashedScheduleId: data.hashedScheduleId,
        assessmentKpiId: data.assessmentKpiId,
      });
    } catch (err) {
      setIsLoading(false);
      // @ts-ignore
      const errorTxt = err?.response?.data?.error || 'エラーが発生しました。お手数ですが最初からやり直してください。'
      alert(errorTxt);
    }
  };

  return (
    <Container className={styles.webappContainer}>
      <Helmet>
        <title>予約確認 | ブランディアBell</title>
      </Helmet>
      {isLoading && (
        <div className={styles.screenOverlay}>
          <Spinner animation="border" variant="info" />
        </div>
      )}

      {onlinePurchaseSchedule && (
        <>
          <h5 className={`mt-5 mb-4 ${styles.boldText}`}>
            予約内容は下記でよろしいでしょうか？
          </h5>

          <ReservationInfo
            startAt={new Date(onlinePurchaseSchedule.startAt)}
            selectedQty={selectedQty}
            remarks={remarks}
          />

          <Form onSubmit={(e) => handleSubmit(e)}>
            <Button
              type="submit"
              className={`mt-4 mb-2 pb-3 pt-3 btn-block ${styles.button}`}
              variant="light"
            >
              予約
              {beforeRequestId && '変更'}
              を確定する
            </Button>
          </Form>

          <div className="text-center my-4">
            <Button
              className={styles.backBtn}
              type="button"
              variant="link"
              onClick={() => {
                history.push('/purchase-request/schedules')
              }}
            >
              変更する
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

export default Component;
