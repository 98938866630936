export enum DeliveryMethod {
    BOX = 'box',
    DIRECT = 'direct',
}

export namespace PurchaseDetailNameSpace {
  export enum JobEnum {
    EMPLOYEE = 'EMPLOYEE',
    TEMPORARY_EMPLOYEE = 'TEMPORARY_EMPLOYEE',
    PART_TIMER = 'PART_TIMER',
    SELF_EMPLOYED = 'SELF_EMPLOYED',
    CIVIL_SERVANT = 'CIVIL_SERVANT',
    STUDENT = 'STUDENT',
    HOUSEWIFE = 'HOUSEWIFE',
    NO_JOBS = 'NO_JOBS',
    OTHER = 'OTHER'
  }
}
