import * as React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Spinner,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import sms from 'line-app/src/assets/images/sms.svg';
import exclamation from 'line-app/src/assets/images/exclamation.svg';

import * as styles from './VerifyMobilePhonePage.scss';
import { sendVerificationCode } from '../RegisterMobilePhonePage/RegisterMobilePhonePage';
import { ReservationDataContext, SetReservationDataContext } from '../../../providers/ReservationContextProvider';
import stepSmsForRegular from '../../../assets/images/steps/reservation2.png';
import stepSmsForRealtime from '../../../assets/images/steps/realtimeReservation2.png';

import SelectedPurchaseItemEntry from '../../molecules/SelectedPurchaseItemEntry/SelectedPurchaseItemEntry';
import SelectedPurchaseRequestEntry from '../../molecules/SelectedPurchaseRequestEntry/SelectedPurchaseRequestEntry';

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

/**
 * SMS認証コード入力ページ(いまスグと通常予約共用)
 */
const Component: React.FC = () => {
  const history = useHistory()
  const { mobilePhone, isRealtime } = React.useContext(ReservationDataContext)
  const setReservationData = React.useContext(SetReservationDataContext)

  const [verificationCode, setVerificationCode] = React.useState('')
  const [invokingVerificationApi, setInvokingVerificationApi] = React.useState(false)
  const [reRegisterMobile, setReRegisterMobile] = React.useState(false);

  const onVerificationCodeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const x = value.replace(/\D/g, '').match(/(\d{0,4})/) || ''
    const newValue = x[1];
    setVerificationCode(newValue);
  };

  React.useEffect(() => {
    if (verificationCode.length === 4) {
      verifyCode();
    }
  }, [verificationCode]);

  /**
   * いまスグ予約完了処理
   */
  const completeRealtimeReservation = async (mobilePhoneWithoutHyphen: string, token: string) => {
    try {
      const { data } = await axios.post<{assessmentKpiId: string, videoCallUrl: string}>(
        `${process.env.API_BASE_URL}/api/web-app/purchase-requests/realtime-complete`,
        {
          mobilePhone: mobilePhoneWithoutHyphen,
          token,
        }
      )

      // GTMデータレイヤー変数に登録
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        assessmentKpiId: data.assessmentKpiId,
      })

      history.push('/realtime-request/complete', {
        videoCallUrl: data.videoCallUrl
      })
    } catch (err) {
      // @ts-ignore
      const errorTxt = err?.response?.data?.error || 'エラーが発生しました。'
      alert(errorTxt);
      setInvokingVerificationApi(false);
    }
  }

  const verifyCode = async () => {
    try {
      setInvokingVerificationApi(true);
      const mobilePhoneWithoutHyphen = '+81' + mobilePhone.replace(/-/g, '').replace(/^0/, '');

      const { data } = await axios.post<{token: string}>(
        `${process.env.API_BASE_URL}/api/web-app/sms-auth/verify`,
        {
          verificationCode,
          mobilePhone: mobilePhoneWithoutHyphen
        }
      )

      if (isRealtime) {
        // いまスグの場合はここで予約確定処理も行う
        return completeRealtimeReservation(mobilePhoneWithoutHyphen, data.token)
      }

      setReservationData((prev) => ({...prev, token: data.token}))

      history.push('/purchase-request/items');
    } catch (err) {
      // @ts-ignore
      const errorTxt = err?.response?.data?.message || 'エラーが発生しました。'
      alert(errorTxt);
      setInvokingVerificationApi(false);
    }
  };

  return (
    <Container className={styles.webappContainer}>
      <Helmet>
        <title>SMS認証 | ブランディアBell</title>
      </Helmet>
      <Row className={`justify-content-center`}>
        <Image className={styles.headerStep} src={isRealtime ? stepSmsForRealtime : stepSmsForRegular} rounded />
      </Row>

      <SelectedPurchaseItemEntry/>
      {!isRealtime && <SelectedPurchaseRequestEntry/>}

      <Row className="p-3 justify-content-md-center">
        <Col md={{ span: 7 }} lg={{ span: 10 }}>
          <div className="mt-4">
            <Row className="justify-content-center mb-3">
              <Image src={sms} className={styles.smsImage} rounded />
            </Row>
            <div className="text-center">
              <p>
                {mobilePhone}に
                <br />
                認証コードを送信しました。
                <br />
                受け取ったコードを入力してください。
              </p>
            </div>
          </div>

          <Form.Group controlId="verifyCode">
            <Form.Control
              className={`text-center ${styles.inputStyle}`}
              type="text"
              name="verifyCode"
              placeholder="_ _ _ _"
              value={verificationCode}
              onChange={onVerificationCodeChange}
              disabled={invokingVerificationApi}
            />
            {invokingVerificationApi && (
              <div className={styles.spinnerContainer}>
                <Spinner animation="border" variant="info" />
              </div>
            )}
          </Form.Group>
          <Button
            type="button"
            className={`mt-4 mb-2 pb-3 pt-3 btn-block ${styles.buttonOutline}`}
            variant="light"
            disabled={reRegisterMobile}
            onClick={() => {
              setReRegisterMobile(true);
              sendVerificationCode(mobilePhone);
            }}
          >
            認証コードを再送信する
          </Button>
          <br />
          <div className="text-left">
            <p className={styles.textSoftRed}>
              <Image src={exclamation} />迷惑メールブロック設定などにより、ショートメール(SMS)が届かない場合がございます。設定をご確認の上、再度お試しください。
              それでも届かない場合は、<a href="mailto:buyer@brandear.jp?subject=SMSの認証コードが届かない&body=※件名は変更せずに送信してください※%0D%0A%0D%0A認証コードが届かない電話番号を教えてください。%0D%0A【電話番号:&emsp;&emsp;&emsp;&emsp;&emsp;】%0D%0A%0D%0A担当者が確認いたしまして折り返しメール連絡をさせて頂きます。%0D%0Aご不便をお掛けしておりますがご連絡まで今しばらくお待ちくださいませ。">こちら</a>からお問い合わせください。
            </p>
          </div>
        </Col>
      </Row>

      <div className="text-center my-4">
        <Button
          className={styles.backBtn}
          type="button"
          variant="link"
          onClick={history.goBack}
        >
          前へ戻る
        </Button>
      </div>
    </Container>
  );
};

export default Component;
